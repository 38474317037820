import {
  PROCESSING_ORDER_STATUS,
  PAYMENT_WAITING_ORDER_STATUS,
  IN_PRODUCTION_ORDER_STATUS,
  ON_HOLD_ORDER_STATUS,
  COMPLETED_ORDER_STATUS,
  PACKAGING_ORDER_STATUS,
  SHIPPING_ORDER_STATUS,
  ON_WAY_ORDER_STATUS
} from '@/constants/order_statuses.js';
import {
  PROCESSING_INVOICE_STATUS,
  PAID_INVOICE_STATUS
} from '@/constants/invoice_statuses.js';

// USA
export const locale = {
  TRANSLATOR: {
    SELECT: 'Select your language',
  },
  MENU: {
    NEW: 'Новый',
    ACTIONS: 'Действия',
    CREATE_POST: 'Создать новый пост',
    PAGES: 'Страницы',
    FEATURES: 'Features',
    APPS: 'Приложения',
    DASHBOARD: 'Панель',
  },
  REYESTR_MENU: [
    {
      title: 'Messages',
      icon: '/img/icons/Group.svg',
      to: 'chats',
      disabled: false,
    },
    {
      title: 'Profile',
      icon: '/img/icons/Document.svg',
      to: 'profile',
      disabled: false,
    },
    {
      title: 'Invoice',
      icon: '/img/icons/Paper.svg',
      to: 'invoice orders',
      disabled: false,
    },
    {
      title: 'Special Order history',
      icon: '/img/icons/Folder.svg',
      disabled: false,
      to: 'special-order-history',
    },
    {
      title: 'Wholesale order',
      icon: '/img/icons/Folder.svg',
      disabled: false,
      to: 'order history',
      subLinks: [],
    },
    {
      title: 'Retail Order History',
      icon: '/img/icons/Folder.svg',
      disabled: false,
      to: 'retail order history',
      subLinks: [],
    },
    {
      title: 'PRICE LIST',
      icon: '/img/icons/Group.svg',
      to: 'price list',
      disabled: false,
    },
    {
      title: 'Product register',
      icon: '/img/icons/Group.svg',
      to: 'reyestr',
      disabled: false,
    },
  ],
  RETAIL_MENU: [
    {
      title: 'Messages',
      icon: '/img/icons/Group.svg',
      to: 'chats',
      disabled: false,
    },
    {
      title: 'Profile',
      icon: '/img/icons/Document.svg',
      to: 'profile',
      disabled: false,
    },
    {
      title: 'Special Order history',
      icon: '/img/icons/Folder.svg',
      disabled: false,
      to: 'special-order-history',
    },
    {
      title: 'Retail Order History',
      icon: '/img/icons/Folder.svg',
      disabled: false,
      to: 'retail order history',
      subLinks: [],
    },
  ],
  SEARCH: {
    SEARCH_KEYWORDS: 'Поиск по ключам...',
  },
  GROUPS: 'Группы',
  PRICE_TITLE: 'Цена',
  SELE_CTED_GROUP: 'Все',
  DROPDOWN: {
    items: ['Все', 'Мужчина', 'Женщина', 'Ребенок', 'Общий'],
  },
  FILTERS: {
    группы: {
      мужчина: false,
      женщина: false,
      ребенок: false,
      общий: false,
    },
    'production technique': {
      'solid-rolled': false,
      сшитый: false,
    },
    Модель: {
      тапочки: false,
      сапоги: false,
    },
  },
  FILTER_KEYS: {
    groups: 'groups',
    production_technique: 'тproduction technique',
    model: 'model',
  },
  AUTH: {
    GENERAL: {
      OR: 'Or',
      SUBMIT_BUTTON: 'Submit',
      NO_ACCOUNT: "Don't have an account?",
      SIGNUP_BUTTON: 'Sign Up',
      FORGOT_BUTTON: 'Forgot Password',
      BACK_BUTTON: 'Back',
      PRIVACY: 'Privacy',
      LEGAL: 'Legal',
      CONTACT: 'Contact',
    },
    LOGIN: {
      TITLE: 'Login Account',
      BUTTON: 'Sign In',
    },
    FORGOT: {
      TITLE: 'Forgot Password?',
      DESC: 'Enter your email to reset your password',
      SUCCESS: 'Your account has been successfully reset.',
    },
    REGISTER: {
      TITLE: 'Sign Up',
      DESC: 'Enter your details to create your account',
      SUCCESS: 'Your account has been successfuly registered.',
    },
    LOGOUT: 'LOGOUT',
    INPUT: {
      EMAIL: 'Email',
      FULLNAME: 'Fullname',
      PASSWORD: 'Password',
      CONFIRM_PASSWORD: 'Confirm Password',
      USERNAME: 'Username',
    },
    VALIDATION: {
      INVALID: '{{name}} is not valid',
      REQUIRED: '{{name}} is required',
      MIN_LENGTH: '{{name}} minimum length is {{min}}',
      AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
      NOT_FOUND: 'The requested {{name}} is not found',
      INVALID_LOGIN: 'The login detail is incorrect',
      REQUIRED_FIELD: 'Required field',
      MIN_LENGTH_FIELD: 'Minimum field length:',
      MAX_LENGTH_FIELD: 'Maximum field length:',
      INVALID_FIELD: 'Field is not valid',
    },
  },
  ECOMMERCE: {
    COMMON: {
      SELECTED_RECORDS_COUNT: 'Selected records count: ',
      ALL: 'All',
      SUSPENDED: 'Suspended',
      ACTIVE: 'Active',
      FILTER: 'Filter',
      BY_STATUS: 'by Status',
      BY_TYPE: 'by Type',
      BUSINESS: 'Business',
      INDIVIDUAL: 'Individual',
      SEARCH: 'Search',
      IN_ALL_FIELDS: 'in all fields',
    },
    ECOMMERCE: 'eCommerce',
    CUSTOMERS: {
      CUSTOMERS: 'Customers',
      CUSTOMERS_LIST: 'Customers list',
      NEW_CUSTOMER: 'New Customer',
      DELETE_CUSTOMER_SIMPLE: {
        TITLE: 'Customer Delete',
        DESCRIPTION: 'Are you sure to permanently delete this customer?',
        WAIT_DESCRIPTION: 'Customer is deleting...',
        MESSAGE: 'Customer has been deleted',
      },
      DELETE_CUSTOMER_MULTY: {
        TITLE: 'Customers Delete',
        DESCRIPTION: 'Are you sure to permanently delete selected customers?',
        WAIT_DESCRIPTION: 'Customers are deleting...',
        MESSAGE: 'Selected customers have been deleted',
      },
      UPDATE_STATUS: {
        TITLE: 'Status has been updated for selected customers',
        MESSAGE: 'Selected customers status have successfully been updated',
      },
      EDIT: {
        UPDATE_MESSAGE: 'Customer has been updated',
        ADD_MESSAGE: 'Customer has been created',
      },
    },
  },
  HEADER: {
    GO_TO_STORE: 'GO TO THE STORE',
    CON_TACTS: 'CONTACTS',
  },
  MAIN: {
    h1: 'Добро пожаловать',
    tumar: 'Tumar',
    logout: 'Выйти',
    info: {
      1: 'С панели своего аккаунта ты можешь посмотреть',
      2: 'недавние заказы',
      3: 'управлять своми',
      4: 'адресами доставки и выставлением счетов',
      5: 'и',
      6: 'редактировать пороль и информацию об аккаунте.',
    },
  },
  PROFILE: {
    balance: 'Your balance',
    order_details: 'Branding details',
    settings_title: 'Settings',
    user_title: 'User',
    edit_user: 'Edit user',
    user_form: [
      'First name',
      'Last name',
      'Disaply name',
      'Email address',
      'Role',
      'Password'
    ],
    change_password: 'Password change',
    password_change_form: [
      'Current password (leave blank to leave unchanged)',
      'New password (leave blank to leave unchanged)',
      'Confirm new password',
    ],
    save_password: 'Save changes',
    add: 'Add',
    delete: 'delete',
    edit: 'edit',
    user: ['User', 'E-mail adress', 'Role', 'Actions', ''],
  },
  INVOICE: {
    orders: {
      total_balance: 'Ваш баланс',
      table_header: [
        'Номер заказа',
        'Сумма заказа',
        'Инвойс',
        'Сумма инвойсов',
        'Статус заказа',
        'Cумма оплаты',
        'Баланс',
        ''
      ],
      view: 'Посмотреть',
    },
    invoices: {
      order_number: 'Order number',
      invoice_table: ['Номер инвойса', 'Дата', 'Статус', 'Сумма ордера', 'Сумма инвойса', 'Actions'],
      view: 'Посмотреть',
      statuses: {
        [PROCESSING_INVOICE_STATUS]: 'Обработка',
        [PAID_INVOICE_STATUS]: 'Оплачен'
      }
    }
  },
  BRENDING: {
    price: 'Price',
    choosePrice: 'Select a price',
    brending_text: 'Branding',
    pre_count: 'Number of selected items',
    colors: 'Choose a colour',
    add_to_cart: 'Add to basket',
    size: 'Choose a size',
    brending_body: {
      place: 'Leave logo',
      price_unchangable: 'The cost is unchanged',
      price_changable: '  The cost will vary depending on the logo',
      replace: 'Replace',
      delete: 'Delete',
    },
  },
  PRODUCT_DESCRIPTION: {
    branding: 'Branding',
    table: {
      image: 'picture',
      details: 'details',
      price: 'price',
      color: 'color',
      size: 'size',
      count: 'count',
      total: 'total',
      pre_count: 'precalculation',
      order_details: 'order details',
    },
    second_table: {
      totalCount: 'Total number',
      sum: 'amount',
      discount: 'discount',
      saving: 'saving',
      delivery: 'delivery',
      total: 'total',
      saved: 'saved',
    },
    go_to_order: 'proceed to checkout',
    notes:
      'Details of logo application on your goods will be provided later by the Manager on chat',
    logo_place: 'logo place',
    order_details: 'Order details',
  },
  REGISTRATION: {
    my_order: 'My order',
    payment_info: 'Payment information',
    first_name: 'Name',
    last_name: 'Surname',
    number: 'Telephone',
    email: 'Email ',
    delivery_adress: 'Enter the delivery address',
    country: 'Country',
    countries: ['Россия', 'Украина', 'Кыргызстан', 'Казахстан', 'Беларусь'],
    address: 'Address',
    home_number: 'House number and street name',
    locality: 'Settlement',
    region: 'Area/region',
    postcode: 'Postcode',
    inn: 'INN',
    your_order: 'Your order',
    coupon_code: 'IF YOU HAVE A COUPON CODE, PLEASE APPLY IT BELOW',
    coupon_placeholder: 'Got a promo code/discount card number?',
    apply_coupon: 'Confirm coupon',
    product: 'Merchandise',
    subtotal: 'Subtotal',
    shipping: 'carriages',
    total: 'Total',
    rule:
      'By placing an order, you agree that TUMAR will store some of your data in order to improve the quality of the service.',
    place_order: 'place an order',
  },
  ORDER_HISTORY: {
    order: 'Ordering',
    ordered: 'Ordered',
    shipped: 'Posted',
    expected_delivery: 'Expected delivery',
    order_number: 'Order number',
    date: 'Date',
    total: 'Total',
    order_info: 'Order information',
    invoice: 'Invoice',
    retry: 'Repeat',
    payment_terminal: 'Payment terminal',
    delivery_address: 'Delivery address',
    order_table: ['Ordering', 'Date', 'Status', 'Total', 'Actions'],
    statuses: {
      [PROCESSING_ORDER_STATUS]: 'In processing',
      [PAYMENT_WAITING_ORDER_STATUS]: 'Pending payment',
      [IN_PRODUCTION_ORDER_STATUS]: 'In production',
      [ON_HOLD_ORDER_STATUS]: 'On hold',
      [COMPLETED_ORDER_STATUS]: 'Done',
      [PACKAGING_ORDER_STATUS]: 'Packaging of goods',
      [SHIPPING_ORDER_STATUS]: 'Dispatch of goods',
      [ON_WAY_ORDER_STATUS]: 'On the way'
    },
  },
  ORDER_COMPLEATE: {
    title: 'Go to order history',
    thanksTitle: 'Thank you. Your order has been accepted.',
  },
  PRICE_LIST: {
    price: 'Price list',
    main: 'Main',
  },
  CART_FOOTER: {
    view: 'View basket',
    ordering: 'Ordering',
    summary: 'Total',
    basket: 'Basket',
    close: 'close',
  },
  ORDER_DETAILS: {
    account: 'My account',
    order_details: 'Order details',
    title: 'ORDER DETAILS',
    table: ['Photo', 'Title', 'Residue', 'Actions'],
    order_details_table: [
      '№',
      'Date',
      'Product name',
      'Quantity of spent branding',
      'Current balance',
    ],
  },
}
