export default {
  currentUser(state) {
    return state.user
  },
  isAuthenticated(state) {
    return state.isAuthenticated
  },
  isCraftsmanLogged (state) {
    return state.isCraftsmanLogged
  },
  getCurrentUserName(state) {
    return state.user && state.user.first_name + ' ' + state.user.last_name
  },
}
