export default {
  SET_ORDER_INVOICES(state, data) {
    state.invoices = data;
  },
  SET_ORDER_INVOICE(state, data) {
    state.invoice = data;
  },
  SET_ORDERS_RESPONSE(state, data) {
    state.ordersResponse = data;
  }
}
