<template>
  <div ref="gallery" v-if="isDesktop" class="pa-0 container">
    <div v-if="props.wrapDesktop === 'nowrap'">
      <!-- <div v-if="componentWidth" class="d-flex" :style="{maxWidth: props.containerWidth}">
        <div v-for="image in props.images" :key="image.id">
            <img :src="'http://ovz2.j1146928.m6x5m.vps.myjino.ru'+image.src" alt="" :style="{
            height: props.imageHeight,
            maxWidth: props.imageWidth,
            minWidth: props.imageWidth,
            marginRight: props.imagePadding}" />
          </div>  
      </div> -->
      <VueSlickCarousel
        variableWidth
        autoplay
        :style="{ maxWidth: props.containerWidth }"
      >
        <template #prevArrow>
          <div class="custom-arrow">
            <svg
              class="slideshow-arrow"
              width="23"
              height="23"
              viewBox="0 0 23 39"
              style="transform: scaleX(-1) scale(0.869565); fill: rgb(0, 0, 0)"
            >
              <path
                d="M857.005,231.479L858.5,230l18.124,18-18.127,18-1.49-1.48L873.638,248Z"
                transform="translate(-855 -230)"
              ></path>
            </svg>
          </div>
        </template>
        <div v-for="image in props.images" :key="image.id">
          <img
            v-lazy="'https://admin.tumar.com' + image.src"
            :alt="getImageName(image.src)" :title="getImageName(image.src)" 

            :style="{
              height: props.imageHeight,
              maxWidth: props.imageWidth,
              minWidth: props.imageWidth,
              marginRight: props.imagePadding,
            }"
          />
        </div>
        <template #nextArrow>
          <div class="custom-arrow">
            <svg
              class="slideshow-arrow"
              style="
                transform: scaleX(1) scale(0.8695652173913043);
                fill: rgb(0, 0, 0);
              "
              width="23"
              height="23"
              viewBox="0 0 23 39"
            >
              <path
                d="M857.005,231.479L858.5,230l18.124,18-18.127,18-1.49-1.48L873.638,248Z"
                transform="translate(-855 -230)"
              ></path>
            </svg>
          </div>
        </template>
      </VueSlickCarousel>
    </div>
    <masonry
      :cols="props.colsDesktop"
      :gutter="props.gutterDesktop"
      v-else-if="props.wrapDesktop === 'masonry'"
      class="image-container"
      :style="{ maxWidth: props.containerWidth }"
    >
      <div class="image-item" v-for="image in props.images" :key="image.id">
        <img
          v-lazy="'https://admin.tumar.com/' + image.src"
          :alt="getImageName(image.src)" :title="getImageName(image.src)" 

          :style="{
            height: 'auto',
            maxWidth: '100%',
            minWidth: props.imageWidth,
            marginRight: props.imagePadding,
            marginBotoom: props.imagePadding,
          }"
        />
      </div>
    </masonry>
    <div
      v-else-if="props.wrapDesktop === 'wrap'"
      ref="containerElemen"
      class="image-container"
      :style="{ maxWidth: props.containerWidth }"
    >
      <div class="image-item" v-for="image in props.images" :key="image.id">
        <img
          v-lazy="'https://admin.tumar.com/' + image.src"
          :alt="getImageName(image.src)" :title="getImageName(image.src)" 

          :style="{
            height: props.imageHeight,
            flex: ` 0 1 calc(${100 / image.length}% - ${props.imagePadding})`,
            maxWidth: `calc(${props.containerWidth} - ${props.imagePadding})`,
            minWidth: props.imageWidth,
            marginRight: props.imagePadding,
            marginBotoom: props.imagePadding,
          }"
        />
      </div>
    </div>
  </div>
  <div v-else class="pa-0 container">
    <VueSlickCarousel
      simple
      arrows
      autoplay
      v-if="props.wrapMobile === 'nowrap'"
      :style="{ maxWidth: props.containerWidth }"
    >
      <template #prevArrow>
        <div class="custom-arrow">
          <svg
            class="slideshow-arrow"
            width="23"
            height="23"
            viewBox="0 0 23 39"
            style="transform: scaleX(-1) scale(0.869565); fill: rgb(0, 0, 0)"
          >
            <path
              d="M857.005,231.479L858.5,230l18.124,18-18.127,18-1.49-1.48L873.638,248Z"
              transform="translate(-855 -230)"
            ></path>
          </svg>
        </div>
      </template>
      <img
        v-for="image in props.images"
        :key="image.id"
        v-lazy="'https://admin.tumar.com/' + image.src"
        :alt="getImageName(image.src)" :title="getImageName(image.src)" 

        :style="{
          height: 'auto',
          maxWidth: '100%',
          minWidth: props.imageWidth,
          marginRight: props.imagePadding,
        }"
      />
      <template #nextArrow>
        <div class="custom-arrow">
          <svg
            class="slideshow-arrow"
            style="
              transform: scaleX(1) scale(0.8695652173913043);
              fill: rgb(0, 0, 0);
            "
            width="23"
            height="23"
            viewBox="0 0 23 39"
          >
            <path
              d="M857.005,231.479L858.5,230l18.124,18-18.127,18-1.49-1.48L873.638,248Z"
              transform="translate(-855 -230)"
            ></path>
          </svg>
        </div>
      </template>
    </VueSlickCarousel>
    <masonry
      :cols="props.colsMobile"
      :gutter="props.gutterMobile"
      v-else-if="props.wrapMobile === 'masonry'"
      class="image-container"
    >
      <div
        v-masonry-tile
        class="image-item"
        v-for="image in props.images"
        :key="image.id"
      >
        <img
          v-lazy="'https://admin.tumar.com/' + image.src"
          :alt="getImageName(image.src)" :title="getImageName(image.src)" 

          :style="{
            // height: 'auto',
            // flex: ` 0 1 calc(${100/image.length}% - ${props.imagePadding})`,
            // maxWidth: '100%',
            // minWidth: props.imageWidth,
            // marginRight: props.imagePadding,
            // marginBotoom: props.imagePadding
          }"
        />
      </div>
    </masonry>
    <div
      v-else-if="props.wrapMobile === 'wrap'"
      ref="containerElemen"
      class="image-container"
      :style="{ maxWidth: '100%' }"
    >
      <div class="image-item" v-for="image in props.images" :key="image.id">
        <img
          :src="'https://admin.tumar.com/' + image.src"
          :alt="getImageName(image.src)" :title="getImageName(image.src)" 

          :style="{
            height: props.imageHeight,
            flex: ` 0 1 calc(${100 / image.length}% - ${props.imagePadding})`,
            maxWidth: `calc(${props.containerWidth} - ${props.imagePadding})`,
            minWidth: props.imageWidth,
            marginRight: props.imagePadding,
            marginBotoom: props.imagePadding,
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import { VueMasonryPlugin } from 'vue-masonry'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import { getImageName } from '@/utils/utils'

export default {
  props: {
    props: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isDesktop: window.innerWidth > 780,
    }
  },
  components: {
    VueSlickCarousel,
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      this.isDesktop = window.innerWidth > 780
    },
    getImageName,
  },
}
</script>

<style scoped>
.image-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.image-item {
  flex: 1;
}

img {
  max-width: 100%;
}
.slick-prev::before,
.slick-next::before {
  display: none;
  opacity: 0;
}
.custom-arrow {
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-arrow svg {
  width: 25px;
  height: 25px;
}
.slick-prev::before,
.slick-next::before {
  display: none;
  opacity: 1;
}
.slick-prev {
  background-color: rgba(255, 255, 255, 0.5);
  opacity: 1;
}
.slick-next {
  background-color: rgba(255, 255, 255, 0.5);
  opacity: 1;
}
.slick-next:hover {
  background-color: rgba(255, 255, 255, 1) !important;
  opacity: 1;
}
.slick-prev:hover {
  background-color: rgba(255, 255, 255, 1) !important;
  opacity: 1;
}
.slick-prev,
.slick-next {
  opacity: 1;
  width: 40px;
  height: 40px;
}
.slick-prev {
  left: 0px !important;
}
.slick-next {
  right: 0px !important;
}

@media (max-width: 979px) {
  .image-container {
    width: 100% !important;
  }
  .image-item img {
    max-width: 100% !important;
    height: auto !important;
  }
}
</style>
