import axios from '@/core/services/api.service'

export default {
  searchProducts({ commit }, text) {
    return axios.post('/frontend/search/product', { text }).then((res) => {
      commit('SET_PRODUCTS', res.data.data)
    })
  },
  setProductByColor({ commit }, data) {
    const params = {
      color: data.color,
      controller_type: data.type,
    }
    return axios
      .post('/frontend/search/find-by-color', params)
      .then((res) => {
        commit('SET_PRODUCTS', res.data.data)
      })
      .catch((error) => {
        console.error('Ошибка при получении данных: ', error)
      })
  },
  getProductBySlug({ dispatch, commit }, { slug }) {
    return axios.get(`/frontend/product/${slug}`).then((res) => {
      commit('SET_PRODUCT', res?.data)
    })
  },
  getSpecialProductBySlug({ dispatch, commit }, { slug }) {
    return axios.get(`/specialgoods/product/${slug}`).then((res) => {
      let obj = {
        product: res?.data.data,
      }
      commit('SET_PRODUCT', obj)
    })
  },
  getAllProducts({ commit }) {
    return axios.get('/frontend/product').then((res) => {
      commit('SET_ALL_PRODUCTS', res.data.data)
    })
  },
  getAllProductsWithFilterPrice({ commit }, data) {
    return axios.post('/frontend/prices/find-by-price', data).then((res) => {
      commit('SET_PRODUCTS', res.data.data)
    })
  },
  getMaxPrice({ commit }) {
    return axios.get('/frontend/prices').then((res) => {
      commit('SET_MAX_PRICE', res.data.max_value)
    })
  },
  productByCatigory({ commit }, { id }) {
    return axios.get(`/frontend/product/category/${id}`).then((res) => {
      commit('SET_PRODUCTS', res.data)
    })
  },
  getSpecialProductColorsBySlag({}, slug) {
    return axios.get(`/specialgoods/product/${slug}/colors`)
  },
  async specialProductByCategory({ commit }, { id }) {
    const preparedCategoryId = id ? `category_id=${id}` : ''
    const queryParams = `${preparedCategoryId}`

    await axios.get(`/specialgoods/product?${queryParams}`).then((res) => {
      commit('SET_PRODUCTS', res.data)
    })
  },
  getAllSpecialCategories({ commit }) {
    return axios.get('/specialgoods/category').then((res) => {
      commit('SET_SPECIAL_CATEGORIES', Object.values(res.data))
      commit('SET_DEFAULT_CATEGORY', Object.values(res.data)[0])
    })
  },
  async productByCatigories({ commit }, { id }) {
    const res = await axios.get(`/frontend/product/category/${id}`)

    return res.data.data
  },
  async specialProductByCatigories({ commit }, { id, count, page }) {
    const preparedCategoryId = id ? `category_id=${id}` : ''
    const preparedCount = count ? `&count=${count}` : ''
    const preparedPage = page ? `&page=${page}` : ''
    const queryParams = `${preparedCategoryId}${preparedCount}${preparedPage}`

    const res = await axios.get(`/specialgoods/product?${queryParams}`)

    return res.data.data
  },
  fetchProductsPage({ commit }, { id, url }) {
    return axios.get(`/frontend/product/category/${id}${url}`).then((res) => {
      commit('SET_PRODUCTS', res.data)
    })
  },
  getProductsMainPage({ commit }) {
    return axios.get('/frontend/product/main').then((res) => {
      commit('SET_MAIN_PRODUCTS', res.data.data)
    })
  },
  getReviews({ commit }, { productId }) {
    return axios.get(`/frontend/review/product/${productId}`).then((res) => {
      commit('SET_REVIEWS', res.data.data)
    })
  },
  postReviews({ commit }, review) {
    return axios
      .post(`/frontend/review/create`, review, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {})
  },
}
