import axios from '@/core/services/api.service'

export default {
  getAllSpecialCategories({ commit }) {
    return axios.get('/specialgoods/category').then((res) => {
      commit('SET_SPECIAL_CATEGORIES', Object.values(res.data))
      commit('SET_DEFAULT_CATEGORY', Object.values(res.data)[0])
    })
  },
  // getSpecialCategoryBySlug({ commit }, slug) {
  //   return axios.get(`/frontend/retail/category/${slug}`).then((res) => {
  //     commit('SET_CATEGORY', res.data)
  //   })
  // },
}
