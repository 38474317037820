export default {
  TOGGLE_SIDE_CART(state, value) {
    state.isSideCartVisible = value
  },
  ADD_CART_ITEM(state, cartItem) {
    cartItem.cartId = state.cartItems.length + 1
    const existingItem = state.cartItems.find((item) => item.id === cartItem.id)
    if (!existingItem) {
      state.cartItems.push(cartItem)
      localStorage.setItem('cartItems', JSON.stringify(state.cartItems))
      return
    }
    if (existingItem.price_type === 'reyestr') {
      // без подошвы
      cartItem.colors.forEach((color) => {
        const existingColor = existingItem.colors.find(
          (item) => item.id === color.id
        )
        if (!existingColor) existingItem.colors.push(color)
      })

      cartItem.sizes.forEach((size) => {
        const existingSize = existingItem.sizes.find(
          (cartSize) =>
            cartSize.id === size.id && cartSize.colorId === size.colorId
        )
        if (!existingSize) existingItem.sizes.push(size)
        else existingSize.count += size.count
      })

      cartItem.selected_brands.forEach((brand) => {
        const existingBrand = existingItem.selected_brands.find(
          (cartBrand) => cartBrand.id === brand.id
        )
        if(existingBrand) existingBrand = brand;
      });
    } else {
      cartItem.soles.forEach((sole) => {
        const existingSole = existingItem.soles.find(
          (item) => item.id === sole.id
        )
        if (!existingSole) existingItem.soles.push(sole)
        else {
          existingSole.selected_brands.forEach((brand, index) => brand = sole.selected_brands[index]);
        }
      })

      cartItem.colors.forEach((color) => {
        const existingColor = existingItem.colors.find(
          (item) => item.id === color.id && item.soleId === color.soleId
        )
        if (!existingColor) existingItem.colors.push(color)
      })

      cartItem.sizes.forEach((size) => {
        const existingSize = existingItem.sizes.find(
          (item) =>
            item.id === size.id &&
            item.colorId === size.colorId &&
            item.soleId === size.soleId
        )
        if (!existingSize) existingItem.sizes.push(size)
        else existingSize.count += size.count
      })
    }
    localStorage.setItem('cartItems', JSON.stringify(state.cartItems))
  },
  CHANGE_CART_ITEM_COUNT(state, { cartId, positionId, increase = false }) {
    state.cartItems.forEach((item) => {
      if (item.cartId === cartId) {
        item.colors.sizes.forEach((size) => {
          if (size.id === positionId) {
            if (increase) {
              size.count++
            } else {
              if (size.count > 1) {
                size.count--
              }
            }
          }
        })
      }
    })
  },
  REMOVE_CART_ITEM(state, { type, itemId, soleId, colorIndex }) {
    const itemToDeleteFrom = state.cartItems.find((item) => item.id === itemId)
    if (type === 'sole') {
      const soleToDeleteColorFrom = itemToDeleteFrom.soles.find(
        (sole) => sole.id === soleId
      )
      soleToDeleteColorFrom.colors.splice(colorIndex, 1)
      const lengthOfSoleColorsWithSizes = soleToDeleteColorFrom.colors.filter(
        (color) => color.sizes.filter((size) => size.count).length
      ).length
      if (!lengthOfSoleColorsWithSizes) {
        itemToDeleteFrom.soles = itemToDeleteFrom.soles.filter(
          (item) => item.id !== soleToDeleteColorFrom.id
        )
        if (!itemToDeleteFrom.soles.length) {
          state.cartItems = state.cartItems.filter(
            (cartItem) => cartItem.id !== itemToDeleteFrom.id
          )
          localStorage.setItem('retailOrder', JSON.stringify(state.cartItems))
        }
      }
    } else {
      itemToDeleteFrom.colors.splice(colorIndex, 1)
      if (!itemToDeleteFrom.colors.length) {
        state.cartItems = state.cartItems.filter(
          (cartItem) => cartItem.id !== itemToDeleteFrom.id
        )
      }
    }
    localStorage.setItem('retailOrder', JSON.stringify(state.cartItems))
    // localStorage.setItem('cart tumar', prune(state.cartItems))
  },
  SET_CART_ITEMS(state, items) {
    state.cartItems = items
    localStorage.setItem('cartItems', JSON.stringify(items))
  },

  REMOVE_ITEM(state, { type, itemId, sizeId, colorId, soleId }) {
    const existingItem = state.cartItems.find((item) => item.id === itemId)
    const savedBrands = JSON.parse(localStorage.getItem('bought_brands'))
    if (type === 'reyestr') {
      // удаление по цвету и идентификатору размера
      let deleteIndex
      existingItem.sizes.forEach((size, index) => {
        if (size.id === sizeId) {
          if (size.colorId === colorId) deleteIndex = index
        }
      })
      let brands
      let deleteColor
      existingItem.colors.forEach((color, index) => {
        if (color.id === colorId) {
          deleteColor = color
        }
      })
      const filteredSizes = existingItem.sizes.filter((item) => {
        if (existingItem.price_type === 'sole') {
          return item?.colorId === colorId && item?.soleId === soleId
        } else {
          return item?.colorId === colorId
        }
      })

      const colorCount = filteredSizes.reduce((acc, curr) => {
        acc += curr.count
        return acc
      }, 0)
      deleteColor?.selected_brands?.forEach((brand) => {
        brands = savedBrands?.map((item) => {
          if (item.id === brand.id) {
            return {
              ...item,
              remainder:
                item.selected_count - +colorCount > +item.starter_remainder
                  ? item.remainder + +colorCount
                  : item.selected_count - +colorCount <
                    +item.starter_remainder &&
                    item.remainder + +colorCount > +item.count
                    ? item.remainder + +colorCount - +item.count
                    : +item.remainder + colorCount,
              selected_count: item.selected_count - +colorCount,
              boughtBrandsKit:
                item.selected_count - +colorCount > +item.starter_remainder
                  ? item.boughtBrandsKit
                  : item.boughtBrandsKit === 0
                    ? item.boughtBrandsKit
                    : item.boughtBrandsKit - +item.count,
            }
          } else {
            return item
          }
        })
      })
      localStorage.setItem(
        'bought_brands',
        JSON.stringify(brands?.filter((el) => el?.selected_count !== 0))
      )
      existingItem.sizes.splice(deleteIndex, 1)
      // удаление цвета если нет для него размеров
      existingItem.colors = existingItem.colors.filter(
        (color) =>
          existingItem.sizes.filter((size) => size.colorId === color.id).length
      )
      // удаление товара если нет цветов
      if (!existingItem.colors.length)
        state.cartItems = state.cartItems.filter(
          (item) => item.id !== existingItem.id
        )
    } else {
      // удаление по подошве, цвету и идентификатору размера
      // удаление размера
      let deleteIndex
      existingItem.sizes.forEach((size, index) => {
        if (size.id === sizeId) {
          if (size.colorId === colorId) {
            if (size.soleId === soleId) {
              deleteIndex = index
            }
          }
        }
      })
      let brands
      let deleteColor
      existingItem.colors.forEach((color, index) => {
        if (color.id === colorId) {
          deleteColor = color
        }
      })
      const filteredSizes = existingItem.sizes.filter((item) => {
        if (existingItem.soles) {
          return item?.colorId === colorId && item?.soleId === soleId
        } else {
          return item?.colorId === colorId
        }
      })

      const colorCount = filteredSizes.reduce((acc, curr) => {
        acc += curr.count
        return acc
      }, 0)
      deleteColor?.selected_brands?.forEach((brand) => {
        brands = savedBrands?.map((item) => {
          if (item.id === brand.id) {
            return {
              ...item,
              remainder:
                item.selected_count - +colorCount > +item.starter_remainder
                  ? item.remainder + +colorCount
                  : item.selected_count - +colorCount <
                    +item.starter_remainder &&
                    item.remainder + +colorCount > +item.count
                    ? item.remainder + +colorCount - +item.count
                    : +item.remainder + colorCount,
              selected_count: item.selected_count - +colorCount,
              boughtBrandsKit:
                item.selected_count - +colorCount > +item.starter_remainder
                  ? item.boughtBrandsKit
                  : item.boughtBrandsKit === 0
                    ? item.boughtBrandsKit
                    : item.boughtBrandsKit - +item.count,
            }
          } else {
            return item
          }
        })
      })
      localStorage.setItem(
        'bought_brands',
        JSON.stringify(brands?.filter((el) => el?.selected_count !== 0))
      )
      existingItem.sizes.splice(deleteIndex, 1)
      // удаление цвета если нет размеров
      existingItem.colors = existingItem.colors.filter(
        (color) =>
          existingItem.sizes.filter(
            (size) => size.soleId === color.soleId && size.colorId === color.id
          ).length
      )
      // удаление подошвы если нет цветов
      existingItem.soles = existingItem.soles.filter(
        (sole) =>
          existingItem.colors.filter((color) => color.soleId === sole.id).length
      )

      // удаление товара если нет подошв
      if (!existingItem.soles.length)
        state.cartItems = state.cartItems.filter(
          (item) => item.id !== existingItem.id
        )
    }
    localStorage.setItem('cartItems', JSON.stringify(state.cartItems))
  },
  REMOVE_ITEM_FROM_SIDE_CART(state, { type, itemId, colorId, soleId }) {
    const existingItem = state.cartItems.find((item) => item.id === itemId)
    const savedBrands = JSON.parse(localStorage.getItem('bought_brands'))
    if (type === 'reyestr') {
      let deleteIndex
      let deleteColor
      let brands
      existingItem.colors.forEach((color, index) => {
        if (color.id === colorId) {
          deleteIndex = index
          deleteColor = color
        }
      })
      const filteredSizes = existingItem.sizes.filter((item) => {
        if (existingItem.price_type === 'sole') {
          return item?.colorId === colorId && item?.soleId === soleId
        } else {
          return item?.colorId === colorId
        }
      })

      const colorCount = filteredSizes.reduce((acc, curr) => {
        acc += curr.count
        return acc
      }, 0)

      deleteColor?.selected_brands?.forEach((brand) => {
        brands = savedBrands?.map((item) => {
          if (item.id === brand.id) {
            return {
              ...item,
              remainder:
                item.selected_count - +colorCount > +item.starter_remainder
                  ? item.remainder + +colorCount
                  : item.selected_count - +colorCount <
                    +item.starter_remainder &&
                    item.remainder + +colorCount > +item.count
                    ? item.remainder + +colorCount - +item.count
                    : +item.remainder + colorCount,
              selected_count: item.selected_count - +colorCount,
              boughtBrandsKit:
                item.selected_count - +colorCount > +item.starter_remainder
                  ? item.boughtBrandsKit
                  : item.boughtBrandsKit === 0
                    ? item.boughtBrandsKit
                    : item.boughtBrandsKit - +item.count,
            }
          } else {
            return item
          }
        })
      })
      localStorage.setItem(
        'bought_brands',
        JSON.stringify(brands?.filter((el) => el?.selected_count !== 0))
      )
      existingItem.colors = existingItem.colors.filter(
        (color) => color.id !== colorId
      )
      if (!existingItem.colors.length)
        state.cartItems = state.cartItems.filter(
          (item) => item.id !== existingItem.id
        )
    } else {
      let deleteIndex
      let deleteColor
      let brands
      existingItem.colors.forEach((color, index) => {
        if (color.id === colorId) {
          if (color.soleId === soleId) {
            deleteIndex = index
            deleteColor = color
          }
        }
      })
      const filteredSizes = existingItem.sizes.filter((item) => {
        if (existingItem.soles) {
          return item?.colorId === colorId && item?.soleId === soleId
        } else {
          return item?.colorId === colorId
        }
      })

      const colorCount = filteredSizes.reduce((acc, curr) => {
        acc += curr.count
        return acc
      }, 0)

      deleteColor?.selected_brands?.forEach((brand) => {
        brands = savedBrands?.map((item) => {
          if (item.id === brand.id) {
            return {
              ...item,
              remainder:
                item.selected_count - +colorCount > +item.starter_remainder
                  ? item.remainder + +colorCount
                  : item.selected_count - +colorCount <
                    +item.starter_remainder &&
                    item.remainder + +colorCount > +item.count
                    ? item.remainder + +colorCount - +item.count
                    : +item.remainder + colorCount,
              selected_count: item.selected_count - +colorCount,
              boughtBrandsKit:
                item.selected_count - +colorCount > +item.starter_remainder
                  ? item.boughtBrandsKit
                  : item.boughtBrandsKit === 0
                    ? item.boughtBrandsKit
                    : item.boughtBrandsKit - +item.count,
            }
          } else {
            return item
          }
        })
      })
      localStorage.setItem(
        'bought_brands',
        JSON.stringify(brands?.filter((el) => el?.selected_count !== 0) || [])
      )

      existingItem.colors.splice(deleteIndex, 1)
      existingItem.soles = existingItem.soles.filter(
        (sole) =>
          existingItem.colors.filter((color) => color.soleId === sole.id).length
      )
      if (!existingItem.soles.length)
        state.cartItems = state.cartItems.filter(
          (item) => item.id !== existingItem.id
        )
    }
    localStorage.setItem('cartItems', JSON.stringify(state.cartItems))
  },
}

function mergeSizes(...args) {
  const arr = []
  args.forEach((a) => arr.push(...a))
  const result = []

  arr.forEach((size) => {
    const existing = result.find((resSize) => resSize.id === size.id)
    if (existing) {
      existing.count += size.count
    } else {
      result.push(size)
    }
  })

  return result
}
