import Vue from 'vue'
import Router from 'vue-router'
import goTo from 'vuetify/lib/services/goto'
import store from '@/core/services/store'
import adminRoutes from './adminRoutes'
import storeRoutes from './storeRoutes'

import { getDataFromLocalStorage } from "@/utils/utils";

Vue.use(Router)
const router = new Router({
  routes: [
    ...adminRoutes,
    ...storeRoutes,
  ],
  mode: 'history',
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((route) => route.meta.requiresAuth)
  const isAuthorized = !store.getters['auth/isAuthenticated']

  if (requiresAuth && isAuthorized) {
    return next({ name: 'login' })
  }

  if (getDataFromLocalStorage('isCraftsmanLogged')) {
    if (to.path.includes('admin')) {
      const isCraftsmanRoute = to.name.includes('craftsman')
      const isCraftsmanAuthPage = ['craftsman-register', 'craftsman-login'].includes(to.name)

      if (isCraftsmanAuthPage) {
        next({ name: 'craftsman-profile' })
      }
      if (!isCraftsmanRoute) {
        next({ name: 'craftsman-profile' })
      }
    }
  }

  goTo(0, { duration: 0 })
  document.title = to.meta.title
  next()
})
export default router
