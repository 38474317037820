var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.props.href)?_c('a',{staticClass:"button py-3 px-10 d-block",style:({
    backgroundColor: _vm.props.backgroundColor,
    color: _vm.props.textColor,
    alignSelf: _vm.props.position
  }),attrs:{"href":_vm.props.href}},[_vm._v(" "+_vm._s(_vm.props.text)+" ")]):_c('button',{staticClass:"button d-block",style:({
    backgroundColor: _vm.props.backgroundColor,
    color: _vm.props.textColor,
    alignSelf: _vm.props.position
  })},[_vm._v(" "+_vm._s(_vm.props.text)+" ")])
}
var staticRenderFns = []

export { render, staticRenderFns }