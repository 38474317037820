<template>
  <div class="d-flex flex-column" style="align-items: center;">
    <h2 class="blogs__title mb-4">{{ props.category }}</h2>
    <v-container fluid class="pa-0">
      <v-row style="display: flex; justify-content: left; overflow: scroll; flex-wrap: nowrap;">
        <v-col @click="openBlog(i)" class="cursor-pointer" style="padding: 0; margin: 6px" cols="3" v-for="(i, index) in blogs.filter((item, index) => index < props.count)" :key="index">
          <div class="blogs__item">
            <img v-lazy="i.photo" :alt="getImageName(i.photo)" :title="getImageName(i.photo)" 
            />
          </div>
          <div class="blogs__text text-center py-2">{{ i.title }}</div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { getImageName } from '@/utils/utils'

const {
  mapState: State_blogs,
  mapActions: Actions_blogs,
} = createNamespacedHelpers('blogs')
export default {
  name: 'Blogs',
  props: {
    props: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...State_blogs(['blogs']),
  },
  methods: {
    ...Actions_blogs(['fetchBlogs']),
    getImageName,
    async openBlog(blog) {
      if (this.$route.params.category) {
        this.$router.push({
          name: 'Generated Blog',
          params: { category: this.blogCategory.url, url: blog.url },
        })
      } else {
        this.$router.push({
          name: 'Generated Blog',
          params: { category: 'category', url: blog.url },
        })
      }
    },
  },
  async mounted() {
    await this.fetchBlogs({ category_id: this.props.categoryId })
  }
}
</script>

<style lang="scss">
.blogs {
  &__item {
    padding-top: 100%;
    border-radius: 2px 2px 0px 0px;
    position: relative;
    img {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  &__text {
    border: 1px solid;
    border-color: transparent #f2f2f2 #f2f2f2 #f2f2f2;
    border-radius: 0px 0px 2px 2px;
    a {
      text-decoration: none;
      color: inherit !important;
    }
  }
}
</style>
