import axios from '../../../api.service'

export default {
  async fetchOrderInvoices({ dispatch, commit }, {order_id}) {
   const api = `wholesale/invoice/orders/${order_id}`
    const response = await axios.get(api)
    commit('SET_ORDER_INVOICES', response?.data.data)
  },
  async fetchOrderInvoice({ dispatch, commit }, {order_id, invoice_id}) {
    const api = `wholesale/invoice/orders/${order_id}/${invoice_id}`
    const response = await axios.get(api);
    commit('SET_ORDER_INVOICE', response?.data)
  },
  async fetchOrders({ commit }, page) {
    const api = `wholesale/invoice/orders?page=${page}`
    const response = await axios.get(api);
    commit('SET_ORDERS_RESPONSE', response?.data)
  }
}
