export default {
  TOGGLE_SIDE_CART(state, value) {
    state.isSideCartVisible = value
  },
  // cartItem.cartId = state.cartItems.length + 1
  // const existingArr = state.cartItems.filter(
  //   (item) => item.product.id === cartItem.product.id
  // )
  // if (!existingArr.length) return state.cartItems.push(cartItem)
  // const existingItem = existingArr.find(
  //   (item) => item.variation.id === cartItem.variation.id
  // )
  // if (!existingItem) return state.cartItems.push(cartItem)

  // const sizesObj = {}
  // existingItem.variation.selectedSizes.forEach((size) => {
  //   sizesObj[size.size] = size.count
  // })

  // cartItem.variation.selectedSizes.forEach((size) => {
  //   if (!sizesObj[size.size]) sizesObj[size.size] = size.count
  //   else sizesObj[size.size] += size.count
  // })
  // existingItem.variation.selectedSizes = Object.keys(sizesObj).map(
  //   (size) => ({
  //     size: Number(size),
  //     count: sizesObj[size],
  //   })
  // )
  ADD_CART_ITEM(state, cartItem) {
    let product

    if (cartItem.isSpecialProduct) {
      state.cartItems.push({
        ...cartItem
      })
    } else {
      if (!state.cartItems.find((item) => item?.size === cartItem?.size)) {
        product = cartItem
        product.size = cartItem?.size
        state.cartItems.push({
          ...product,
          cartId: cartItem?.size?.size + String(cartItem.variation.id),
        })
      }
    }

    localStorage.setItem('cart tumar store', JSON.stringify(state.cartItems))
  },
  REMOVE_CART_ITEM(state, cartId) {
    state.cartItems = state.cartItems.filter((item) => {
      return item.cartId !== cartId
    })

    localStorage.setItem('cart tumar store', JSON.stringify(state.cartItems))
  },
  CHANGE_CART_ITEM_COUNT(state, { cartId, isIncrement }) {
    state.cartItems = state.cartItems.map((item) => {
      if (isIncrement) {
        return item.cartId === cartId
          ? {
              ...item,
              size: {
                ...item?.size,
                count: item?.size?.count + 1,
              },
            }
          : item
      } else {
        return item.cartId === cartId
          ? {
              ...item,
              size: {
                ...item?.size,
                count:
                  item?.size?.count > 1
                    ? item?.size?.count - 1
                    : item?.size.count,
              },
            }
          : item
      }
    })
  },
  CLEAR_CART_ITEMS(state) {
    state.cartItems = []
    localStorage.setItem('cart tumar store', JSON.stringify([]))
  },
  SET_SELECTED_PRODUCT(state, product) {
    state.selectedProduct = product
  },
  SET_SELECTED_PRODUCT_COUNT(state, value) {
    state.selectedProduct.count = value
  },
  CLEAN_PRODUCT(state) {
    state.selectedProduct = {}
  },
}
