var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-carousel',{staticClass:"slider carousel",staticStyle:{"position":"relative"},style:({
    width: _vm.props.width,
    height: _vm.props.height,
    minHeight: _vm.props.height,
    marginTop: _vm.props.marginTop + 'px',
    marginBottom: _vm.props.marginBottom + 'px',
    marginLeft: _vm.props.marginLeft + 'px',
    marginRight: _vm.props.marginRight + 'px',
  }),attrs:{"interval":_vm.props.interval,"hide-delimiters":"","show-arrows":true,"height":_vm.props.height},model:{value:(_vm.activeCarouselItem),callback:function ($$v) {_vm.activeCarouselItem=$$v},expression:"activeCarouselItem"}},[_vm._l((_vm.props.items),function(item){return _c('v-carousel-item',{key:item.id,attrs:{"src":!item.image.includes('http') ? 'https://admin.tumar.com/' + item.image : item.image}},[_c('div',{staticClass:"big-slider__content-wrapper",style:({
          justifyContent: item.alignSelf
        })},[_c('div',{staticClass:"big-slider__content",style:({
        width: item.width,
        fontWeight: item.fontWeight,
        background: 'https://admin.tumar.com/' + item.image,
      })},[_c('p',{staticClass:"big-slider__p",style:({
            color: '#FFFFFF',
            fontSize: item.fontSize + 'px',
          }),domProps:{"innerHTML":_vm._s(item?.content)}}),_vm._l((item?.buttons),function(itemButton,key){return _c('div',{key:key,staticClass:"big-slider__button-wrapper",style:({
              justifyContent: itemButton.justifyContent
            })},[_c('a',{attrs:{"href":itemButton.href}},[_c('button',{style:({
                  width: itemButton.width,
                  height: itemButton.height,
                  color: itemButton.buttonTextColor,
                  fontSize: itemButton.fontSize + 'px',
                  fontWeight: itemButton.fontWeight,
                  alignSelf: itemButton.alignSelf,
                  background: itemButton.buttonColor,
                })},[_vm._v(_vm._s(itemButton.textButton))])])])})],2)])])}),_c('div',{staticClass:"slider_dots"},[_c('ul',{staticClass:"pa-0"},_vm._l((_vm.props.items.length),function(item,index){return _c('li',{key:index,staticClass:"dots",class:{ dots_active: index === _vm.activeCarouselItem },on:{"click":function($event){_vm.activeCarouselItem = index}}})}),0)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }