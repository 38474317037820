<template>
  <div class="carousel__wrapper">
    <VueSlickCarousel
      v-if="!isDesktop"
      class="slider-big__mobile"
      id="slider__mobile"
      ref="c1"
      simple
      arrows
      autoplay
      variableWidth
      infinite
    >
      <template #prevArrow>
        <div class="custom-arrow">
          <svg
            class="slideshow-arrow"
            width="23"
            height="23"
            viewBox="0 0 23 39"
            style="transform: scaleX(-1) scale(0.869565); fill: rgb(0, 0, 0)"
          >
            <path
              d="M857.005,231.479L858.5,230l18.124,18-18.127,18-1.49-1.48L873.638,248Z"
              transform="translate(-855 -230)"
            ></path>
          </svg>
        </div>
      </template>
      <div
        v-for="image in props.images"
        :key="image.id"
        class="carousel__item pr-1"
      >
        <img
          v-lazy="'https://admin.tumar.com/' + image.src"
          :alt="getImageName(image.src)" :title="getImageName(image.src)" 
        />
      </div>
      <template #nextArrow>
        <div class="custom-arrow">
          <svg
            class="slideshow-arrow"
            style="
              transform: scaleX(1) scale(0.8695652173913043);
              fill: rgb(0, 0, 0);
            "
            width="23"
            height="23"
            viewBox="0 0 23 39"
          >
            <path
              d="M857.005,231.479L858.5,230l18.124,18-18.127,18-1.49-1.48L873.638,248Z"
              transform="translate(-855 -230)"
            ></path>
          </svg>
        </div>
      </template>
    </VueSlickCarousel>
    <VueSlickCarousel
      v-else
      class="slider-big"
      ref="c1"
      variableWidth
      centerMode
      :slidesToShow="1"
      :infinite="true"
      :arrows="true"
      v-bind="{
        slidesToScroll: 1,
        focusOnSelect: true,
      }"
    >
      <template #prevArrow>
        <div class="custom-arrow">
          <svg
            class="slideshow-arrow"
            width="23"
            height="23"
            viewBox="0 0 23 39"
            style="transform: scaleX(-1) scale(0.869565); fill: rgb(0, 0, 0)"
          >
            <path
              d="M857.005,231.479L858.5,230l18.124,18-18.127,18-1.49-1.48L873.638,248Z"
              transform="translate(-855 -230)"
            ></path>
          </svg>
        </div>
      </template>
      <div
        v-for="image in props.images"
        :key="image.id"
        class="carousel__item pr-1"
      >
        <img
          v-lazy="'https://admin.tumar.com/' + image.src"
          :alt="getImageName(image.src)" :title="getImageName(image.src)" 

        />
      </div>
      <template #nextArrow>
        <div class="custom-arrow">
          <svg
            class="slideshow-arrow"
            style="
              transform: scaleX(1) scale(0.8695652173913043);
              fill: rgb(0, 0, 0);
            "
            width="23"
            height="23"
            viewBox="0 0 23 39"
          >
            <path
              d="M857.005,231.479L858.5,230l18.124,18-18.127,18-1.49-1.48L873.638,248Z"
              transform="translate(-855 -230)"
            ></path>
          </svg>
        </div>
      </template>
    </VueSlickCarousel>
    <div class="carousel__mini-wrapper">
      <VueSlickCarousel
        class="slider-nav"
        centerMode
        ref="c2"
        autoplay
        :asNavFor="c1"
        :slidesToShow="5"
        :arrows="false"
        v-bind="{
          slidesToScroll: 1,
          focusOnSelect: true,
        }"
      >
        <div
          v-for="image in props.images"
          :key="image.id"
          style="width: auto"
          class="carousel__mini-item pr-1"
        >
          <img
            v-lazy="'https://admin.tumar.com/' + image.src"
            :alt="getImageName(image.src)" :title="getImageName(image.src)" 

          />
        </div>
      </VueSlickCarousel>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import { getImageName } from '@/utils/utils'

export default {
  props: {
    props: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    c1: undefined,
    c2: undefined,
  }),
  computed: {
    isDesktop() {
      if (window.innerWidth > 980) {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    getImageName,
  },
  mounted() {
    this.c1 = this.$refs.c1
    this.c2 = this.$refs.c2
  },
  components: {
    VueSlickCarousel,
  },
}
</script>

<style lang="scss" scoped>
.slider-big {
  overflow: hidden;
}
.carousel {
  &__wrapper,
  &__list {
    width: 100%;
  }
  &__list,
  &__mini {
    overflow-x: scroll;
  }
  &__list {
    height: 460px;
  }
  &__item {
    height: 460px;
    width: inherit !important;
    min-width: 300px;
    min-height: 450px;
    // max-width: 300px;
  }
  &__mini {
    width: fit-content;
  }
  &__mini-item {
    min-width: 50px;
    height: 50px;
  }
  &__item,
  &__mini-item {
    img {
      width: inherit;
      height: 100%;
      display: block;
      object-fit: cover;
    }
  }
}
@media (min-width: 980px) {
  .carousel__mini-wrapper {
    width: 600px;
    margin: auto;
  }
}
@media (max-width: 979px) {
  #slider__mobile .slick-track {
    display: flex !important;
  }

  .slick-slide {
    height: inherit !important;
  }
  .carousel__mini-wrapper {
    width: 100%;
    margin: auto;
  }
}
.custom-arrow {
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-arrow svg {
  width: 25px;
  height: 25px;
}
.slick-prev::before,
.slick-next::before {
  display: none;
  opacity: 0;
}
.slider-big:hover .slick-prev {
  background-color: rgba(255, 255, 255, 0.5);
  opacity: 1;
}
.slider-big:hover .slick-next {
  background-color: rgba(255, 255, 255, 0.5);
  opacity: 1;
}
.slick-next:hover {
  background-color: rgba(255, 255, 255, 1) !important;
  opacity: 1;
}
.slick-prev:hover {
  background-color: rgba(255, 255, 255, 1) !important;
  opacity: 1;
}
.slick-prev,
.slick-next {
  opacity: 0;
  width: 40px;
  height: 40px;
}
.slick-prev {
  left: 0px !important;
}
.slick-next {
  right: 0px !important;
}

@media (max-width: 979px) {
  .slick-prev,
  .slick-next {
    opacity: 1;
    width: 40px;
    height: 40px;
  }
  /* .slick-prev::before, .slick-next::before {
  display: none;
  opacity: 1;
} */
  .slick-next {
    background-color: rgba(255, 255, 255, 0.5) !important;
    opacity: 1;
  }
  .slick-prev {
    background-color: rgba(255, 255, 255, 0.5) !important;
    opacity: 1;
  }
}
</style>
<style>
.slick-track {
  display: flex !important;
  flex-direction: row;
}
</style>
<!-- <style lang="scss">
.carousel__wrapper {
  width: 100% !important;
  margin: 0 auto;
  .slick-track {
    margin: 0 auto;
  }
  .slick-prev:before,
  .slick-next:before {
    color: black;
  }
  .slick-initialized .slick-slide {
    width: auto !important;
  }
}
.slick-track.slick-center {
  width: 1000;
}
.slick-next {
  right: 28px;
  z-index: 9;
}
iframe.ql-video {
  width: 100%;
  height: 400px;
}
.slick-prev,
.slick-next {
  z-index: 1;
  background: #ffffff96;
  width: 40px;
  height: 40px;
}

.nav-block .slick-prev,
.nav-block .slick-next {
  background: transparent;
  display: none !important;
}

.slick-prev {
  left: 18px;
}

.slick-prev:before,
.slick-next:before {
  opacity: 0.2;
  color: white;
}

.nav-block .slick-prev:before,
.nav-block .slick-next:before {
  opacity: 0.7;
  color: black;
} 
</style> -->
