const storeRoutes = [
  {
    path: '/',
    component: () => import('@/view/layout/store/StoreLayout.vue'),
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('@/view/pages/store/HomeSecond.vue'),
        // component: () => import('@/view/pages/HomePage.vue'),
        // component: () => import('@/view/pages/store/Home.vue'),
        meta: {
          title: 'Home',
        },
      },
      {
        name: 'Artisans',
        path: '/artisans',
        component: () => import('@/view/pages/AllArtisans.vue'),
        meta: {
          title: 'All artisans',
        },
      },
      {
        name: 'Craftsman',
        path: '/craftsman/:id',
        component: () => import('@/view/pages/craftsman/id.vue'),
        meta: {
          title: 'Craftsman profile',
        },
      },
      // {
      //   path: '/shop',
      //   name: 'home',
      //   component: () => import('@/view/pages/store/Home.vue'),
      //   meta: {
      //     title: 'Home',
      //   },
      // },
      {
        name: 'About',
        path: 'about',
        component: () => import('@/view/pages/store/About.vue'),
        meta: {
          title: ' About us',
        },
      },
      // {
      //   name: 'Contact us',
      //   path: 'contact-us',
      //   component: () => import('@/view/pages/store/ContactUs.vue'),
      //   meta: {
      //     title: ' Contact us',
      //   },
      // },
      {
        name: 'Production',
        path: 'production',
        component: () => import('@/view/pages/store/Production.vue'),
        meta: {
          title: ' Production',
        },
      },
      // {
      //   name: 'FAQ',
      //   path: 'faq',
      //   component: () => import('@/view/pages/store/FAQ.vue'),
      //   meta: {
      //     title: ' FAQ',
      //   },
      // },
      {
        path: 'order',
        name: 'Order',
        component: () => import('@/view/pages/store/basket/Order.vue'),
        children: [
          {
            path: 'basket',
            name: 'store basket',
            component: () => import('@/view/pages/store/basket/Cart.vue'),
            meta: {
              title: ' Cart',
            },
          },
          {
            path: 'registration',
            name: 'Registration',
            component: () =>
              import('@/view/pages/store/basket/Registration.vue'),
            meta: {
              title: ' Registration',
            },
          },
          {
            path: 'order-complete/:id',
            name: 'Order complete',
            component: () =>
              import('@/view/pages/store/basket/OrderComplete.vue'),
            meta: {
              title: ' Order complete',
            },
          },
        ],
      },
      {
        name: 'Product',
        path: 'product/:id',
        component: () => import('@/view/pages/store/Product.vue'),
        meta: {
          title: ' Product',
        },
      },
      {
        name: 'Order history',
        path: 'order-history/:id',
        component: () => import('@/view/pages/order-history/ProcutCreatedByCompany.vue'),
        meta: {
          title: 'Order History',
        },
      },
      {
        name: 'Special-product',
        path: 'special-product/:id',
        component: () => import('@/view/pages/store/SpecialProduct.vue'),
        meta: {
          title: ' Special Product',
        },
      },
      {
        name: 'Categories',
        path: 'categories/:url',
        component: () => import('@/view/pages/store/Categories.vue'),
        meta: {
          title: ' Categories',
        },
      },
      {
        name: 'CategoriesMain',
        path: 'categories',
        component: () => import('@/view/pages/store/CategoriesMain.vue'),
        meta: {
          title: ' CategoriesMain',
        },
      },
      {
        name: 'Search',
        path: 'search',
        component: () => import('@/view/pages/store/Search.vue'),
        meta: {
          title: ' Search',
        },
      },
      // {
      //   name: 'Shop',
      //   path: 'shop/:slug',
      //   component: () => import('@/view/pages/store/Shop.vue'),
      //   meta: {
      //     title: ' Shop',
      //   },
      // },
      {
        name: 'Blogs',
        path: 'blog',
        component: () => import('@/view/pages/store/Blogs.vue'),
        meta: {
          title: ' Blogs',
        },
      },
      {
        name: 'Blog categories',
        path: '/blog/:category',
        component: () => import('@/view/pages/store/Blogs.vue'),
        meta: {
          title: ' Blog categories',
        },
      },
      {
        name: 'Generated Blog',
        path: '/blog/:category/:url',
        component: () => import('@/view/pages/store/Blog.vue'),
        meta: {
          title: ' Blog',
        },
      },
      {
        name: 'Generated Page',
        path: '/pages/:url',
        component: () => import('@/view/pages/store/Filler.vue'),
        meta: {
          title: ' Pages',
        },
      },
      {
        path: '*',
        name: '404',
        component: () => import('@/view/pages/error/Error-1.vue'),
        meta: {
          title: 'Page not found',
        },
      },
    ],
  }
]

export default storeRoutes
