import axios from '../../../api.service'

export default {
  fetchOrderDetails({ commit }) {
    return axios.get(`/wholesale/branding/currents/`).then((res) => {
      commit('SET_ORDERS_DETAILS', res.data.data)
    })
  },
  fetchOrderDetailsById({ commit }, id) {
    return axios.get(`/wholesale/branding/history/${id}`)
  },
  postBrandRemainder(_, item) {
    const amount = Math.ceil(item?.boughtBrandsKit / +item.count) * +item.count
    return axios
      .post('/wholesale/branding/add', {
        reyestrId: item.reyestrId,
        brandId: item.id,
        amount: amount,
      })
      .then((res) => {
        localStorage.setItem('bought_brands', JSON.stringify([]))
        return res
      })
  },
  useBrandRemainder(_, brand) {
    return axios
      .post('/wholesale/branding/use', {
        reyestrId: brand.reyestrId,
        brandId: brand.id,
        amount: brand.selected_count,
      })
      .then((res) => {
        localStorage.setItem('bought_brands', JSON.stringify([]))
        return res
      })
  },
  // saveBrandCount({ commit }, brands) {
  //   commit('SET_BRAND_COUNT', brands)
  // },
  removeSavedBrands({ commit }, brands) {
    commit('REMOVE_SAVED_BRANDS', brands)
  },
}
