import adminCraftsmanRoutes from "./adminCraftsmanRoutes";

const adminRoutes = [
  {
    path: '/admin',
    component: () => import('@/view/layout/Layout'),
    children: [
        ...adminCraftsmanRoutes,
      {
        path: '/',
        redirect: {
          name: 'chat',
        },
      },
      {
        name: 'profile',
        path: 'profile',
        component: () => import('@/view/pages/Profile'),
        meta: {
          title: 'Admin | Client Profile',
          requiresAuth: true,
        },
      },
      {
        name: 'timeline',
        path: 'timeline',
        component: () => import('@/view/pages/Timeline'),
        meta: {
          title: 'Admin | Timeline',
          requiresAuth: true,
        },
      },
      {
        name: 'reyestr',
        path: 'reyestr',
        component: () => import('@/view/pages/AdminReyestrProductList.vue'),
        meta: {
          title: 'Admin | Reyestr',
          requiresAuth: true,
        },
      },
      {
        name: 'product item',
        path: 'reyestr/product/:id',
        component: () => import('@/view/pages/AdminReyestrProduct.vue'),
        meta: {
          title: 'Admin | Product item',
          requiresAuth: true,
        },
      },
      {
        name: 'price list',
        path: 'price-list',
        component: () => import('@/view/pages/PriceList'),
        meta: {
          title: 'Admin | Price List',
          requiresAuth: true,
        },
      },
      {
        name: 'price list item',
        path: 'price-list/product/:id',
        component: () => import('@/view/pages/ProductAccountPriceList'),
        meta: {
          title: 'Admin | Price list item',
          requiresAuth: true,
        },
      },
      {
        name: 'pre-order',
        path: 'pre-order',
        component: () => import('@/view/pages/PreOrder'),
        meta: {
          title: 'Admin | Pre-order',
          requiresAuth: true,
        },
      },
      {
        name: 'mailbox',
        path: 'mailbox',
        component: () => import('@/view/pages/Mailbox'),
        meta: {
          title: 'Admin | Mail Box',
          requiresAuth: true,
        },
      },
      {
        name: 'compose',
        path: 'compose',
        component: () => import('@/view/pages/Compose'),
        meta: {
          title: 'Admin | Compose Message',
          requiresAuth: true,
        },
      },
      {
        name: 'read mail',
        path: 'read-mail',
        component: () => import('@/view/pages/ReadMail'),
        meta: {
          title: 'Admin | Read Mail',
          requiresAuth: true,
        },
      },
      {
        name: 'invoice orders',
        path: 'invoice/orders',
        component: () => import('@/view/pages/invoice/InvoiceOrders'),
        meta: {
          title: 'Admin | Invoice',
          requiresAuth: true,
        },
      },
      {
        name: 'invoice order',
        path: 'invoice/order/:id',
        component: () => import('@/view/pages/invoice/InvoiceOrder'),
        meta: {
          title: 'Admin | Invoice',
          requiresAuth: true,
        },
      },
      {
        name: 'adming basket',
        path: 'basket',
        component: () => import('@/view/pages/admin-basket/AdminBasket.vue'),
        meta: {
          title: 'Admin | Your Shoping Basket',
          requiresAuth: true,
        },
      },
      {
        path: 'order',
        component: () => import('@/view/pages/admin-basket/AdminOrder.vue'),
        name: 'admin order',
        children: [
          {
            path: 'registration',
            name: 'admin order registration',
            component: () => import('@/view/pages/admin-basket/AdminOrderRegistration.vue'),
            meta: {
              title: 'Admin | Registration',
              requiresAuth: true,
            },
          },
          {
            path: 'order-complete',
            name: 'admin order complete',
            component: () => import('@/view/pages/admin-basket/AdminOrderComplete.vue'),
            meta: {
              title: 'Admin | Order complete',
              requiresAuth: true,
            },
          },
        ],
      },
      {
        name: 'order history',
        path: 'order-history',
        component: () => import('@/view/pages/order-history/OrderHistory'),
        meta: {
          title: 'Admin | Order History',
          requiresAuth: true,
        },
      },
      {
        name: 'order history details',
        path: 'order-history/details/:id',
        component: () => import('@/view/pages/order-history/OrderHistoryDetails'),
        meta: {
          title: 'Admin | Order History Details',
          requiresAuth: true,
        },
      },
      {
        name: 'special-order-history',
        path: 'special-order-history',
        component: () => import('@/view/pages/order-history/SpecialOrderHistory'),
        meta: {
          title: 'Admin | Special Order History',
          requiresAuth: true,
        },
      },
      {
        name: 'special-order-history-details',
        path: 'special-order-history/details/:id',
        component: () => import('@/view/pages/order-history/SpecialOrderHistoryDetails.vue'),
        meta: {
          title: 'Admin | Special Order History Details',
          requiresAuth: true,
        },
      },
      {
        name: 'retail order history',
        path: 'retail-order-history',
        component: () => import('@/view/pages/order-history/RetailOrderHistory'),
        meta: {
          title: 'Admin | Retail Order History',
          requiresAuth: true,
        },
      },
      {
        name: 'retail order history details',
        path: 'retail-order-history/details/:id',
        component: () => import('@/view/pages/order-history/RetailOrderHistoryDetails.vue'),
        // component: () => import('@/view/content/OrderDetails.vue'),
        meta: {
          title: 'Admin | Retail Order History Details',
          requiresAuth: true,
        },
      },
      {
        name: 'order details',
        path: 'order-details',
        component: () => import('@/view/pages/OrderDetails'),
        meta: {
          title: 'Admin | Order Details',
          requiresAuth: true,
        },
      },
      // {
      //   name: 'craft order details',
      //   path: 'craft-order-details',
      //   component: () => import('@/view/pages/CraftOrderDetails'),
      //   meta: {
      //     title: 'Admin | Order Details',
      //     requiresAuth: true,
      //   },
      // },
      {
        path: 'chats',
        component: () => import('@/view/pages/Chat.vue'),
        children: [
          {
            path: '/',
            name: 'chats',
            components: {
              chatsList: () => import('@/view/pages/ChatList.vue'),
            },
            meta: {
              title: 'Admin | Chat',
              requiresAuth: true,
              hideFooter: true,
            },
          },
          {
            path: ':id',
            name: 'chat dialog',
            components: {
              chatsList: () => import('@/view/pages/ChatList.vue'),
              chatDialog: () => import('@/view/pages/ChatDialog.vue'),
              chatDialogWholesale: () =>
                import('@/view/pages/ChatDialogWholesale.vue'),
            },
            meta: {
              title: 'Admin | Chat',
              requiresAuth: true,
              hideFooter: true,
            },
          },
        ],
      },
      {
        path: 'chat',
        component: () => import('@/view/pages/Chat.vue'),
        children: [
          {
            path: '/',
            name: 'chat',
            components: {
              // chatDialog: () => import('@/view/pages/ChatDialog.vue'),
              chatsList: () => import('@/view/pages/ChatList.vue'),
            },
            meta: {
              title: 'Admin | Chat',
              requiresAuth: true,
              hideFooter: true,
            },
          },
        ],
      },
    ],
  },
  {
    name: 'login',
    path: '/admin/login',
    component: () => import('@/view/pages/auth/Login'),
    meta: {
      title: 'Admin | Log in',
    },
  },
  {
    name: 'craftsman-login',
    path: '/admin/craftsman/login',
    component: () => import('@/view/pages/auth/Login'),
    meta: {
      title: 'Admin | Log in',
    },
  },
  {
    name: 'craftsman-register',
    path: '/admin/craftsman/register',
    component: () => import('@/view/pages/auth/Register'),
    meta: {
      title: 'Admin | Registration Page',
    },
  },
  {
    name: 'register',
    path: '/admin/register',
    component: () => import('@/view/pages/auth/Register'),
    meta: {
      title: 'Admin | Registration Page',
    },
  },
  {
    name: 'register wholesale',
    path: '/admin/register_wholesale',
    component: () => import('@/view/pages/auth/RegisterWholesale'),
    meta: {
      title: 'Admin | Registration Page Wholesale',
    },
  },
  {
    name: 'recover password',
    path: '/admin/recover-password',
    component: () => import('@/view/pages/auth/RecoverPassword'),
    meta: {
      title: 'Admin | Recover Password',
    },
  },
  {
    name: 'forgot password',
    path: '/admin/forgot-password',
    component: () => import('@/view/pages/auth/ForgotPassword'),
    meta: {
      title: 'Admin | Forgot Password',
    },
  },
]

export default adminRoutes
