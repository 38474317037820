var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:({
    marginTop: _vm.props.marginTopValue + 'px',
    marginBottom: _vm.props.marginBottomValue + 'px',
    marginLeft: _vm.props.marginLeftValue + 'px',
    marginRight: _vm.props.marginRightValue + 'px',
  })},[_c('div',{staticClass:"imageContaner",style:({ width: _vm.props.width, height: _vm.props.height }),on:{"mouseover":function($event){_vm.isHover = true},"mouseleave":function($event){_vm.isHover = false}}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:('https://admin.tumar.com/' + _vm.props.src),expression:"'https://admin.tumar.com/' + props.src"}],attrs:{"alt":_vm.getImageName(_vm.props.src),"title":_vm.getImageName(_vm.props.src)}}),_c('transition',{attrs:{"name":"fade"}},[(_vm.isHover)?_c('div',{staticClass:"image-container__content"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.props.description || _vm.props.href),expression:"props.description || props.href"}],staticClass:"d-flex flex-column align-center"},[_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.props.description),expression:"props.description"}],staticClass:"image-container__p text-center mb-3",domProps:{"innerHTML":_vm._s(_vm.props.description)}}),_c('a',{directives:[{name:"show",rawName:"v-show",value:(_vm.props.buttonText),expression:"props.buttonText"}],staticClass:"image-container__link px-10 py-2",style:({
        color: _vm.props.buttonTextColor,
        backgroundColor: _vm.props.buttonColor,
      }),attrs:{"href":_vm.props.href}},[_vm._v(" "+_vm._s(_vm.props.buttonText)+" ")])])]):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }