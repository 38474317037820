<template>
  <div class="artisans">
    <div class="artisans__title-block">
      <h1 class="artisans__title">
        WORKSHOPS
      </h1>
    </div>

    <div class="artisans__wrapper">
      <div class="artisans__content">
        <div
            v-for="(artisan, i) in artisans"
            :key="i"
            class="artisans__member"
        >
          <div class="artisans__image-block">
            <img :src="$options.baseUrl + artisan.profile_pic" :alt="getImageName(artisan.profile_pic)" :title="getImageName(artisan.profile_pic)" 
            >
          </div>

          <div class="artisans__name-block">
            <span class="artisans__name">{{ artisan.name }}</span>
            <span class="artisans__position">{{ artisan.position }}</span>
          </div>
          <div class="artisans__select">
            <button
                class="artisans__select-btn"
                @click.prevent="$router.push({ name: 'Craftsman', params: { id: artisan.id } })"
            >
              Select
            </button>
          </div>
        </div>
      </div>

      <div class="artisans__pagination">
        <button
            class="artisans__prev"
            :class="[page === 1 ? 'artisans__disabled-btn':'artisans__pagination-prev']"
            @click="prev"
        >
          Previous
        </button>
        <span class="artisans__current-page">{{ page }}</span>
        <button
            class="artisans__next"
            :class="[page < countOfPages ? 'artisans__pagination-next':'artisans__disabled-btn']"
            @click="next"
        >
          Next
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/core/services/api.service'
import { getImageName } from '@/utils/utils'

export default {
  name: "AllArtisans",
  data () {
    return {
      page: 1,
      totalPage: 1,
      pagesPerPage: 20,
      artisans: []
    }
  },
  computed: {
    countOfPages () {
      return Math.ceil(this.totalPage / this.pagesPerPage)
    }
  },
  watch: {
    page () {
      this.getAllArtisans()
    }
  },
  mounted() {
    this.getAllArtisans()
  },
  methods: {
    getImageName,
    getAllArtisans () {
      axios.get(`/specialgoods/artisan?page=${this.page}`)
          .then((res) => {
            this.artisans = res.data.data
            this.totalPage = res.data.total
            this.pagesPerPage = res.data.per_page
            window.scrollTo(0,0);
          })
    },
    prev () {
      if (this.page > 1) {
        this.page -= 1
      }
    },
    next () {
      if (this.page < this.countOfPages) {
        this.page += 1
      }
    },
  },
  baseUrl: 'https://admin.tumar.com' //'http://ovz2.j1146928.m6x5m.vps.myjino.ru'
}
</script>

<style scoped lang="scss">
.artisans {
  color: #666666;
  width: 978px;
  margin: 0 auto;

  &__select {
    position: absolute;
    bottom: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    right: 0;
    width: 100%;

    &-btn {
      background: #ccc;
      border: 1px solid #ccc;
      padding: 5px 10px;
      transition: all .3s;
      min-width: 100px;

      &:hover {
        background: #ccc;
      }
    }
  }

  &__disabled-btn {
    background: #ccc;
    border: 1px solid #ccc;
    padding: 5px 10px;
    transition: all .3s;
    min-width: 80px;
    color: #b9afaf;

    &:hover {
      cursor: not-allowed;
    }
  }

  &__wrapper {
    margin: 0 0 110px;
  }
  &__next, &__prev {
    background-color: rgb(242, 242, 242);
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    border-width: 0;
    padding: 12px 20px;
    border-style: unset;
    min-width: 120px;
  }
  &__pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  &__content {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(222px, 1fr));
    grid-auto-rows: 420px;
    gap: 30px;
    margin: 36px 0 50px;
  }
  &__member {
    display: flex;
    flex-direction: column;
    position: relative;
  }
  &__name-block {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
  }
  &__name {
    font-size: 20px;
    font-weight: 700;
    line-height: 30.8px;
    text-align: left;
  }
  &__position {
    font-size: 14px;
    font-weight: 400;
    line-height: 17.85px;
    text-align: left;
  }
  &__image-block {
    height: 338px;
    background: #888;
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &__title-block {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 195px;
  }
  &__title {
    font-size: 32px;
    font-weight: 700;
    line-height: 56.25px;
    text-align: center;
    text-transform: uppercase;
  }
  &__subtitle {
    font-size: 14px;
    font-weight: 700;
    line-height: 17.85px;
    text-align: center;
    max-width: 400px;
  }
}
</style>
