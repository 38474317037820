var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isDesktop)?_c('div',{ref:"gallery",staticClass:"pa-0 container"},[(_vm.props.wrapDesktop === 'nowrap')?_c('div',[_c('VueSlickCarousel',{style:({ maxWidth: _vm.props.containerWidth }),attrs:{"variableWidth":"","autoplay":""},scopedSlots:_vm._u([{key:"prevArrow",fn:function(){return [_c('div',{staticClass:"custom-arrow"},[_c('svg',{staticClass:"slideshow-arrow",staticStyle:{"transform":"scaleX(-1) scale(0.869565)","fill":"rgb(0, 0, 0)"},attrs:{"width":"23","height":"23","viewBox":"0 0 23 39"}},[_c('path',{attrs:{"d":"M857.005,231.479L858.5,230l18.124,18-18.127,18-1.49-1.48L873.638,248Z","transform":"translate(-855 -230)"}})])])]},proxy:true},{key:"nextArrow",fn:function(){return [_c('div',{staticClass:"custom-arrow"},[_c('svg',{staticClass:"slideshow-arrow",staticStyle:{"transform":"scaleX(1) scale(0.8695652173913043)","fill":"rgb(0, 0, 0)"},attrs:{"width":"23","height":"23","viewBox":"0 0 23 39"}},[_c('path',{attrs:{"d":"M857.005,231.479L858.5,230l18.124,18-18.127,18-1.49-1.48L873.638,248Z","transform":"translate(-855 -230)"}})])])]},proxy:true}],null,false,2039423065)},_vm._l((_vm.props.images),function(image){return _c('div',{key:image.id},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:('https://admin.tumar.com' + image.src),expression:"'https://admin.tumar.com' + image.src"}],style:({
            height: _vm.props.imageHeight,
            maxWidth: _vm.props.imageWidth,
            minWidth: _vm.props.imageWidth,
            marginRight: _vm.props.imagePadding,
          }),attrs:{"alt":_vm.getImageName(image.src),"title":_vm.getImageName(image.src)}})])}),0)],1):(_vm.props.wrapDesktop === 'masonry')?_c('masonry',{staticClass:"image-container",style:({ maxWidth: _vm.props.containerWidth }),attrs:{"cols":_vm.props.colsDesktop,"gutter":_vm.props.gutterDesktop}},_vm._l((_vm.props.images),function(image){return _c('div',{key:image.id,staticClass:"image-item"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:('https://admin.tumar.com/' + image.src),expression:"'https://admin.tumar.com/' + image.src"}],style:({
          height: 'auto',
          maxWidth: '100%',
          minWidth: _vm.props.imageWidth,
          marginRight: _vm.props.imagePadding,
          marginBotoom: _vm.props.imagePadding,
        }),attrs:{"alt":_vm.getImageName(image.src),"title":_vm.getImageName(image.src)}})])}),0):(_vm.props.wrapDesktop === 'wrap')?_c('div',{ref:"containerElemen",staticClass:"image-container",style:({ maxWidth: _vm.props.containerWidth })},_vm._l((_vm.props.images),function(image){return _c('div',{key:image.id,staticClass:"image-item"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:('https://admin.tumar.com/' + image.src),expression:"'https://admin.tumar.com/' + image.src"}],style:({
          height: _vm.props.imageHeight,
          flex: ` 0 1 calc(${100 / image.length}% - ${_vm.props.imagePadding})`,
          maxWidth: `calc(${_vm.props.containerWidth} - ${_vm.props.imagePadding})`,
          minWidth: _vm.props.imageWidth,
          marginRight: _vm.props.imagePadding,
          marginBotoom: _vm.props.imagePadding,
        }),attrs:{"alt":_vm.getImageName(image.src),"title":_vm.getImageName(image.src)}})])}),0):_vm._e()],1):_c('div',{staticClass:"pa-0 container"},[(_vm.props.wrapMobile === 'nowrap')?_c('VueSlickCarousel',{style:({ maxWidth: _vm.props.containerWidth }),attrs:{"simple":"","arrows":"","autoplay":""},scopedSlots:_vm._u([{key:"prevArrow",fn:function(){return [_c('div',{staticClass:"custom-arrow"},[_c('svg',{staticClass:"slideshow-arrow",staticStyle:{"transform":"scaleX(-1) scale(0.869565)","fill":"rgb(0, 0, 0)"},attrs:{"width":"23","height":"23","viewBox":"0 0 23 39"}},[_c('path',{attrs:{"d":"M857.005,231.479L858.5,230l18.124,18-18.127,18-1.49-1.48L873.638,248Z","transform":"translate(-855 -230)"}})])])]},proxy:true},{key:"nextArrow",fn:function(){return [_c('div',{staticClass:"custom-arrow"},[_c('svg',{staticClass:"slideshow-arrow",staticStyle:{"transform":"scaleX(1) scale(0.8695652173913043)","fill":"rgb(0, 0, 0)"},attrs:{"width":"23","height":"23","viewBox":"0 0 23 39"}},[_c('path',{attrs:{"d":"M857.005,231.479L858.5,230l18.124,18-18.127,18-1.49-1.48L873.638,248Z","transform":"translate(-855 -230)"}})])])]},proxy:true}],null,false,2039423065)},_vm._l((_vm.props.images),function(image){return _c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:('https://admin.tumar.com/' + image.src),expression:"'https://admin.tumar.com/' + image.src"}],key:image.id,style:({
        height: 'auto',
        maxWidth: '100%',
        minWidth: _vm.props.imageWidth,
        marginRight: _vm.props.imagePadding,
      }),attrs:{"alt":_vm.getImageName(image.src),"title":_vm.getImageName(image.src)}})}),0):(_vm.props.wrapMobile === 'masonry')?_c('masonry',{staticClass:"image-container",attrs:{"cols":_vm.props.colsMobile,"gutter":_vm.props.gutterMobile}},_vm._l((_vm.props.images),function(image){return _c('div',{directives:[{name:"masonry-tile",rawName:"v-masonry-tile"}],key:image.id,staticClass:"image-item"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:('https://admin.tumar.com/' + image.src),expression:"'https://admin.tumar.com/' + image.src"}],style:({
          // height: 'auto',
          // flex: ` 0 1 calc(${100/image.length}% - ${props.imagePadding})`,
          // maxWidth: '100%',
          // minWidth: props.imageWidth,
          // marginRight: props.imagePadding,
          // marginBotoom: props.imagePadding
        }),attrs:{"alt":_vm.getImageName(image.src),"title":_vm.getImageName(image.src)}})])}),0):(_vm.props.wrapMobile === 'wrap')?_c('div',{ref:"containerElemen",staticClass:"image-container",style:({ maxWidth: '100%' })},_vm._l((_vm.props.images),function(image){return _c('div',{key:image.id,staticClass:"image-item"},[_c('img',{style:({
          height: _vm.props.imageHeight,
          flex: ` 0 1 calc(${100 / image.length}% - ${_vm.props.imagePadding})`,
          maxWidth: `calc(${_vm.props.containerWidth} - ${_vm.props.imagePadding})`,
          minWidth: _vm.props.imageWidth,
          marginRight: _vm.props.imagePadding,
          marginBotoom: _vm.props.imagePadding,
        }),attrs:{"src":'https://admin.tumar.com/' + image.src,"alt":_vm.getImageName(image.src),"title":_vm.getImageName(image.src)}})])}),0):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }