import chats from '@/core/mock/chats.json'

export default {
  chats,
  messages: [],
  nextPage: '',
  markedMessages: [],
  media: [],
  isScrollTop: false,
  fileMessages: [],
  chatInfo: {},
  isNewMessage: false,
}
