<template>
  <!-- <div class="coming-soon" v-if="!accessGranted">
    <h1>Coming Soon</h1>
    <div>
      <p>Здравствуйте уважаемый пользователь!</p>
      <p>Сайт на данный момент находится на реконструкции.</p>
      <div class="access_to_web">
        <input
          v-model="password"
          @keyup.enter="checkPassword"
          class="password"
        />
        <button class="password_button" @click="checkPassword">Submit</button>
      </div>
      <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
    </div>
  </div> -->
  <v-app>
    <router-view></router-view>

    <v-dialog
      v-model="showCookieModal"
      persistent
      max-width="500px"
      class="cookie-modal"
      hide-overlay
    >
      <v-card>
        <v-card-title>Consent to the use of Сookies</v-card-title>
        <v-card-text>
          By clicking “Accept all cookies”, you agree Stack Exchange can store
          cookies on your device and disclose information in accordance with our
          <a @click="$router.push({ path: '/pages/cookie-policy' })"
            >Cookie Policy.</a
          >
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="acceptCookies">Accept all</v-btn>
          <v-btn color="primary" @click="closeCookies">Сlose</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="alerts">
      <v-alert
        v-for="(item, index) in alerts"
        :key="item.id"
        :type="item.type"
        :class="{ 'mb-0': index === alerts.length - 1 }"
      >
        {{ item.text }}
      </v-alert>
    </div>
  </v-app>
</template>

<style lang="scss">
// 3rd party plugins css
@import '~bootstrap-vue/dist/bootstrap-vue.css';
@import '~icheck-bootstrap/icheck-bootstrap.min.css';
@import '~@fortawesome/fontawesome-free/css/all.css';

// Main demo style scss

@import 'assets/app.css';
@import 'assets/store.css';
@import '../public/fonts/Alegreya_Sans/alegreyasans.css';
/* @import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@400;500;700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
body {
  font-family: 'Alegreya Sans';
  font-size: 1rem;
}
.pages .crafted-component.container-v-craft.main-canvas > .crafted-component:first-child {
  padding-top: 86px !important;
}
.pages .crafted-component.container-v-craft.main-canvas > .crafted-component:first-child .v-window.slider.carousel {
  margin-top: -130px !important;
}
.pages .crafted-component.container-v-craft.main-canvas > .crafted-component:first-child.v-window.slider.carousel {
  margin-top: -130px !important;
}

.password {
  background: white !important;
  outline: none;
  width: 300px;
  height: 40px;
  padding-left: 20px;
  font-size: 20px;
}
.profile-container .password {
  padding-left: 0;
}
.password_button {
  width: 90px;
  height: 40px;
  background: black !important;
  color: white;
}
.access_to_web {
  display: flex;
  align-items: center;
  justify-content: center;
}

.coming-soon {
  background: url('/img/bg.jpg');
  background-size: cover;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.form-control {
  font-size: 16px;
}
p:not(span > p):not(.big_slider_text > div > p):not(.icon-content__text):not(
    .icon-content__text > p
  ):not(.icon-content__title):not(.icon-content__title > p)
  :not(.icon-content__link > p),
span {
  font-weight: 400;
  color: #757474;
  font-size: 17px;
  line-height: 20.4px;
}
img:not([src$='.png']) {
  cursor: pointer;
}
/* img[src$='.gif'] {
  width: 200px !important;
  height: 200px !important;
} */
h1,
h2,
h3,
h4,
h5,
h6,
b,
strong:not(p > a > strong) {
  font-weight: bold;
  color: #666666;
}
a {
  font-weight: 500;
}

.coming-soon h1 {
  font-size: 80px !important;
}
.coming-soon p {
  font-size: 26px !important;
  margin-bottom: 10px !important;
  color: black !important;
}

.login-box,
.register-box {
  width: 360px;
}
@media (max-width: 576px) {
  .pages
    .crafted-component.container-v-craft.main-canvas
    > .crafted-component:first-child {
    padding-top: 58px !important;
  }

  .login-page {
    width: 100%;
  }
  .login-box,
  .register-box {
    margin: 0 auto;
    width: 90%;
  }
}
.social-auth-links {
  margin: 10px 0;
  font-size: 16px;
  a {
    font-size: 16px;
  }
}
.card {
  box-shadow: 0 0 1px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 20%) !important;
}
.right {
  position: absolute;
  right: 1rem;
  top: 0.7rem;
}

.login-box-msg,
.register-box-msg {
  margin: 0 !important;
  padding: 0 20px 20px;
  text-align: center;
  font-size: 16px;
}
.login {
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #e9ecef;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100vh;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  .form-group label {
    font-size: 16px;
    font-weight: 700;
  }
}
.login-logo,
.register-logo {
  font-size: 2.1rem;
  font-weight: 300;
  margin-bottom: 0.9rem;
  text-align: center;
}

.login-box .login-logo a,
.register-logo > a {
  color: #495057;
  font-size: 33px;
}

.login {
  .v-list-item {
    font-size: 16px;
  }
}
.dark-mode .login-logo a,
.dark-mode .register-logo a {
  color: #fff;
}

.login-card-body,
.register-card-body {
  background-color: #fff;
  border-top: 0;
  color: #666;
  padding: 20px;
}
.v-autocomplete__content {
  box-shadow: none !important;
  .v-select-list.v-list.v-sheet {
    background-color: #454d55;
    border: 1px solid #56606a;
    border-radius: 0 0 4px 4px;
    padding: 0;
    a {
      color: #c2c7d0 !important;
    }
  }
}

.circle {
  width: 15px;
  height: 15px;
  border-radius: 40px;
  background: #e6e6e6;
  display: inline-block;
}
// list colors
.blue {
  background-color: rgba(73, 96, 124, 1);
}
.black {
  background-color: rgba(60, 58, 58, 1);
}
.grey {
  background-color: rgba(140, 139, 140, 1);
}
.green {
  background-color: rgba(105, 113, 94, 1);
}
.orange {
  background-color: rgba(251, 179, 101, 1);
}
.yellow {
  background-color: rgba(234, 217, 154, 1);
}
.purple {
  background-color: rgba(187, 185, 213, 1);
}
.red {
  background-color: rgba(177, 71, 84, 1);
}
.white {
  background-color: rgba(245, 244, 240, 1) !important;
}
.cursor-pointer {
  cursor: pointer;
}
.v-input__slot fieldset {
  display: none;
}
</style>
<style scoped>
.v-sheet {
  position: fixed !important;
  bottom: 20px;
  left: 20px;
  max-width: 370px;
  width: 100%;
  a {
    text-decoration: underline;
  }
}
.v-card__actions {
  width: 100%;
  button {
    width: 48%;
  }
}
@media (max-width: 900px) {
  .v-sheet {
    bottom: 70px;
  }
}
@media (max-width: 425px) {
  .v-sheet {
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.alerts {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 2099;
}
</style>
<script>
import axios from '@/core/services/api.service'

import { createNamespacedHelpers } from 'vuex'
const { mapState } = createNamespacedHelpers('alerts')
const { mapActions } = createNamespacedHelpers('blogCategories')
const { mapActions: Actions_blogs } = createNamespacedHelpers('blogs')
const { mapActions: Actions_faq } = createNamespacedHelpers('faq')
const { mapActions: Actions_menu } = createNamespacedHelpers('menu')
const { mapActions: Actions_pages } = createNamespacedHelpers('pages')
const { mapState: State_auth, mapActions: Actions_auth } =
  createNamespacedHelpers('auth')

export default {
  name: 'App',
  computed: {
    ...mapState(['alerts']),
    ...State_auth(['localeLang']),
  },
  data() {
    return {
      password: '',
      accessGranted: false,
      errorMessage: '',
      correctPassword: 'superhero',
      showCookieModal: false,
    }
  },
  methods: {
    ...mapActions(['fetchBlogCategories', 'fetchBlogCategoryByUrl']),
    ...Actions_auth(['setLocaleLang']),
    ...Actions_blogs(['fetchBlogs', 'fetchBlogByUrl']),
    ...Actions_faq(['fetchFAQs']),
    ...Actions_menu(['fetchMenus']),
    ...Actions_pages(['fetchPageByUrl']),
    acceptCookies() {
      localStorage.setItem('cookiesAccepted', true)
      this.showCookieModal = false
    },
    closeCookies() {
      this.showCookieModal = false
    },
    checkAccess() {
      const access = localStorage.getItem('accessGranted')
      if (access) {
        this.accessGranted = true
      }
    },
    checkPassword() {
      if (this.password === this.correctPassword) {
        this.accessGranted = true
        localStorage.setItem('accessGranted', true)
        this.errorMessage = ''
      } else {
        this.errorMessage = 'Incorrect password. Please try again.'
      }
    },
    fetchDataByLang() {
      // blog category
      if (this.$route.name === 'Blog categories') {
        this.fetchBlogCategoryByUrl(this.$route.params.category)
      }

      // faq
      this.fetchFAQs()

      // blog
      if (this.$route.name === 'Generated Blog') {
        this.fetchBlogByUrl(this.$route.params.url)
      }

      // menu
      const menuPositions = [
        'top_left',
        'top_right',
        'bottom_left',
        'bottom_right',
      ]
      // pages
      if (this.$route.name === 'Generated Page') {
        this.fetchPageByUrl(this.$route.params.url)
      }
    },
    async fetchHeadContent() {
      try {
        const data = await axios.get('/frontend/head')
        return data.data[0].content
      } catch {}
    },
    async addScripts() {
      const string = await this.fetchHeadContent()
      // document.head.innerHTML = string + document.head.innerHTML
      // const scriptElement = document.createElement('script')
      // scriptElement.textContent = "alert('hi')" // работает

      // document.head.appendChild(scriptElement)
      document.head.innerHTML = document.head.innerHTML + `${string}`
    },
  },
  async created() {
    this.checkAccess()
    const sessionLocale = window.sessionStorage.getItem('tumar-locale')
    if (sessionLocale) {
      this.setLocaleLang(sessionLocale)
    } else {
      this.setLocaleLang(navigator.language.split('-')[0])
    }
    this.fetchDataByLang()
    // await this.$nextTick()
    this.addScripts()

    if (!localStorage.getItem('cookiesAccepted')) {
      this.showCookieModal = true
    }
  },
}
</script>
