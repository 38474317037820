const ID_TOKEN_KEY = 'id_token'

class JwtService {
  #ID_TOKEN_KEY = 'id_token'
  getToken() {
    return window.localStorage.getItem(this.#ID_TOKEN_KEY)
  }

  saveToken(token, remember) {
    // @todo implement remember functionality
    window.localStorage.setItem(this.#ID_TOKEN_KEY, token)
  }

  destroyToken() {
    window.localStorage.removeItem(this.#ID_TOKEN_KEY)
  }
}

const instance = new JwtService()

export default instance
