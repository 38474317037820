<template>
  <div>
    <CoolLightBox
      :items="props.images"
      :index="previewIndex"
      @close="previewIndex = null"
    ></CoolLightBox>
    <v-container class="pa-0">
      <div v-html="props.title"></div>
      <v-row class="justify-space-around ma-0">
        <v-col cols="9" class="py-0 pl-0">
          <transition name="slide-left" mode="out-in">
            <div
              :key="selectedImage"
              style="padding-top: 100%; position: relative"
            >
              <div
                style="position: absolute; inset: 0"
                @click="previewIndex = currentIndex"
              >
                <img
                  v-lazy="'https://admin.tumar.com/' + selectedImage"
                  :alt="getImageName(selectedImage)"
                  :title="getImageName(selectedImage)"
                  style="width: 100%; height: 100%"
                />
              </div>
            </div>
          </transition>
        </v-col>
        <v-col cols="3" style="max-height: 90vh; overflow-y: scroll">
          <v-row class="ma-0">
            <template v-for="(image, imageIndex) in props.images">
              <v-col v-if="image" :key="imageIndex" cols="12">
                <div
                  @click="
                    () => {
                      if (!props.images[imageIndex]) {
                        return
                      }
                      selectedImage = image
                      currentIndex = imageIndex
                    }
                  "
                  style="
                    cursor: pointer;
                    padding-top: 100%;
                    background-size: contain;
                    background-position: center;
                  "
                  :style="{
                    backgroundImage: `url('${image}')`,
                  }"
                ></div>
              </v-col>
            </template>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row class="justify-space-between">
        <v-col cols="3">
          <div v-html="props.description"></div>
        </v-col>
        <v-col cols="4">
          <div v-html="props.moreInfo"></div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import { getImageName } from '@/utils/utils'

export default {
  props: {
    props: {
      type: Object,
      default: () => {},
    },
  },
  components: { CoolLightBox },
  data() {
    return {
      selectedImage: '',
      currentIndex: null,
      previewIndex: null,
    }
  },
  methods: {
    getImageName,
  },
  watch: {
    'props.images': {
      immediate: true,
      handler(val) {
        if (val && val.length) {
          this.selectedImage = val[0]
          if (val[0]) {
            this.currentIndex = 0
          }
        }
      },
    },
  },
}
</script>

<style scoped>
.slide-left-enter-active,
.slide-left-leave-active {
  transition: all 0.3s ease;
}
.slide-left-leave-to,
.slide-left-enter {
  transform: translateX(30px);
  opacity: 0;
}
</style>
