import { saveDataToLocalStorage } from "@/utils/utils";

export default {
  SET_AUTH(state) {
    state.isAuthenticated = true
  },
  SET_USER(state, user) {
    state.user = user
  },
  SET_CRAFTSMAN_LOGGED(state) {
    state.isCraftsmanLogged = true
    saveDataToLocalStorage('isCraftsmanLogged', state.isCraftsmanLogged)
  },
  SET_LOCALE_LANG(state, lang) {
    state.localeLang = lang
  },
  SET_LOCALES(state, langs) {
    state.locales = langs
  },
}
