export const saveDataToLocalStorage = (key, data) => {
  window.localStorage.setItem(key, JSON.stringify(data))
}
export const getDataFromLocalStorage = (key) => {
  return JSON.parse(window.localStorage.getItem(key))
}
export const deleteDataFromLocalStorage = (key) => {
  window.localStorage.removeItem(key)
}
export const getImageName = (url) => {
    
    const segments = url.split('/');
    let filename = segments.pop() || '';
  
    const nameWithoutExtension = filename.split('.').slice(0, -1).join('.') || filename;
  
    return nameWithoutExtension;
}
