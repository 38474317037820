import axios from 'axios'
import JwtService from '@/core/services/jwt.service'
import router from './router'

/**
 * Service to call HTTP request via Axios
 */
// const API_URL = 'http://localhost:8000'
// const API_URL = 'http://ovz2.j1146928.m6x5m.vps.myjino.ru'
// const API_URL = 'https://tumar-back.test'
const API_URL = 'https://admin.tumar.com'


axios.defaults.baseURL = API_URL + '/api'
axios.defaults.headers.common[
  'Authorization'
] = `Bearer ${JwtService.getToken()}`

axios.interceptors.request.use((config) => {
      const token = JwtService.getToken();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    },
    (error) => {
  return Promise.reject(error);
})

axios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error.response.status === 401) {
      if (error.config.url.includes('login')) {
        return Promise.reject(error)
      }

      router.push({ name: 'login' })
    }

    return Promise.reject(error)
  }
)

export default axios
