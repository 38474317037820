export const PROCESSING_ORDER_STATUS = 'Processing';
export const PAYMENT_WAITING_ORDER_STATUS = 'payment_waiting';
export const IN_PRODUCTION_ORDER_STATUS = 'in_production';
export const ON_HOLD_ORDER_STATUS = 'on_hold';
export const COMPLETED_ORDER_STATUS = 'completed';
export const PACKAGING_ORDER_STATUS = 'packaging';
export const SHIPPING_ORDER_STATUS = 'shipping';
export const ON_WAY_ORDER_STATUS = 'on_way';

export const ORDER_STATUSES_COLORS = {
    [PROCESSING_ORDER_STATUS]: 'text-secondary',
    [PAYMENT_WAITING_ORDER_STATUS]: 'text-red',
    [IN_PRODUCTION_ORDER_STATUS]: 'text-secondary',
    [ON_HOLD_ORDER_STATUS]: 'text-red',
    [COMPLETED_ORDER_STATUS]: 'text-success',
    [PACKAGING_ORDER_STATUS]: 'text-secondary',
    [SHIPPING_ORDER_STATUS]: 'text-secondary',
    [ON_WAY_ORDER_STATUS]: 'text-secondary'
};