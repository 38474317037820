var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('CoolLightBox',{attrs:{"items":_vm.props.images,"index":_vm.previewIndex},on:{"close":function($event){_vm.previewIndex = null}}}),_c('v-container',{staticClass:"pa-0"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.props.title)}}),_c('v-row',{staticClass:"justify-space-around ma-0"},[_c('v-col',{staticClass:"py-0 pl-0",attrs:{"cols":"9"}},[_c('transition',{attrs:{"name":"slide-left","mode":"out-in"}},[_c('div',{key:_vm.selectedImage,staticStyle:{"padding-top":"100%","position":"relative"}},[_c('div',{staticStyle:{"position":"absolute","inset":"0"},on:{"click":function($event){_vm.previewIndex = _vm.currentIndex}}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:('https://admin.tumar.com/' + _vm.selectedImage),expression:"'https://admin.tumar.com/' + selectedImage"}],staticStyle:{"width":"100%","height":"100%"},attrs:{"alt":_vm.getImageName(_vm.selectedImage),"title":_vm.getImageName(_vm.selectedImage)}})])])])],1),_c('v-col',{staticStyle:{"max-height":"90vh","overflow-y":"scroll"},attrs:{"cols":"3"}},[_c('v-row',{staticClass:"ma-0"},[_vm._l((_vm.props.images),function(image,imageIndex){return [(image)?_c('v-col',{key:imageIndex,attrs:{"cols":"12"}},[_c('div',{staticStyle:{"cursor":"pointer","padding-top":"100%","background-size":"contain","background-position":"center"},style:({
                  backgroundImage: `url('${image}')`,
                }),on:{"click":() => {
                    if (!_vm.props.images[imageIndex]) {
                      return
                    }
                    _vm.selectedImage = image
                    _vm.currentIndex = imageIndex
                  }}})]):_vm._e()]})],2)],1)],1)],1),_c('v-container',[_c('v-row',{staticClass:"justify-space-between"},[_c('v-col',{attrs:{"cols":"3"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.props.description)}})]),_c('v-col',{attrs:{"cols":"4"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.props.moreInfo)}})])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }