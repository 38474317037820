<template>
  <div class="container-tabs">
    <div :style="props" :class="props.classes" class="scrollable-container">
      <v-tabs class="d-flex justify-center container-tabs__header" hide-slider v-model="active"
        :class="{ 'mb-6': $vuetify.breakpoint.smAndDown }">
        <v-tab v-for="(item, index) in props.items" :key="index" :class="{ 'px-10': $vuetify.breakpoint.mdAndUp }"
          :ripple="false">
          {{ item.title }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="active" class="container-tabs__content">
        <template v-if="children.length">
          <recursion v-for="(ch, index) in children" :key="index" :content="ch" :componentName="ch.props.component ? ch.props.component : ch.componentName
            " />
        </template>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BuilderTabs',
  provide() {
    return {
      tab: () => this.activeTab,
    }
  },
  props: {
    props: {
      type: Object,
      default: () => { },
    },
    children: {
      type: Array,
      default: () => [],
    },
    content: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return { active: 0 }
  },
  computed: {
    activeTab() {
      return this.active
    },
  },
}
</script>

<style lang="scss">
.container-tabs {
  &__header {
    position: sticky;
    top: 84px;
    z-index: 5;
    background-color: rgba(255, 255, 255, 0.5411764706);

    .v-tabs-bar {
      background-color: rgba(255, 255, 255, 0.5411764706) !important;
    }
  }
  &__content {
    margin-top: 40px;
  }
}
.v-tabs {
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    flex-wrap: nowrap;
    overflow-x: auto;
  }
}

.v-tab {
  text-transform: none;

  &:not(:last-child) {
    border-right: 1px solid #e6e6e6;
  }

  &.v-tab {
    color: #9a9a9a !important;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.07em;
  }

  &.v-tab--active {
    color: #423f3d !important;
  }
}
</style>
