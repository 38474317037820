import axios from '@/core/services/api.service'
import router from '@/core/services/router'
import JwtService from '@/core/services/jwt.service'
import { getDataFromLocalStorage, deleteDataFromLocalStorage } from "@/utils/utils";

export default {
  login({ dispatch, commit }, { email, password, remember = false }) {
    return axios
      .post('/wholesale/login', { email, password })
      .then(({ data }) => {
        JwtService.saveToken(data.access_token, remember)
        axios.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${data.access_token}`
        dispatch('fetchProfile')
        commit('SET_AUTH')
      })
  },
  loginCraftsman({ dispatch, commit }, { email, password, remember = false }) {
    return axios
        .post('/specialgoods/login', { email, password })
        .then(({ data }) => {
          JwtService.saveToken(data.access_token, remember)

          setTimeout(() => {
            const token = JwtService.getToken();
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

            commit('SET_AUTH');
            commit('SET_CRAFTSMAN_LOGGED');
          }, 100);

        })
  },
  registerCraftsman({ commit, dispatch }, credentials) {
    return axios
        .post('/specialgoods/register', credentials, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((res) => {
          if (res.data.success) {
            dispatch('loginCraftsman', {
              email: credentials.email,
              password: credentials.password_confirmation,
            })
          }
          return res
        })
  },
  fetchCraftsmanProfile({ commit }) {
    return axios.get('/specialgoods/auth/user').then((res) => {
      // localStorage.setItem('client_type', res.data.role)
      localStorage.setItem('user_id', JSON.stringify(res.data.data.artisans_id))
      commit('SET_USER', res.data.data)
    })
  },
  updateCraftsmanProfile ({ commit, dispatch }, payload) {
    return axios.put('/specialgoods/auth/user', payload).then((res) => {
      dispatch('fetchCraftsmanProfile')
    })
  },
  loginRetail({ dispatch, commit }, { email, password, remember = false }) {
    return axios
      .post(
        '/frontend/login',
        { email, password },
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json;charset=utf-8',
          },
        }
      )
      .then(({ data }) => {
        JwtService.saveToken(data.access_token, remember)
        axios.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${data.access_token}`
        // dispatch('fetchRetailProfile')
        commit('SET_AUTH')
      })
  },
  fetchProfile({ commit }) {
    return axios.get('/wholesale/profile').then((res) => {
      localStorage.setItem('client_type', res.data.role)
      localStorage.setItem('user_id', JSON.stringify(res.data.id))
      commit('SET_USER', res.data)
    })
  },
  fetchRetailProfile({ commit }) {
    return axios.get('/frontend/profile').then((res) => {
      localStorage.setItem('client_type', res.data.role)
      localStorage.setItem('user_id', JSON.stringify(res.data.id))
      commit('SET_USER', res.data)
    })
  },
  updateProfile(_, payload) {
    return axios.put('/wholesale/profile', payload).then((res) => {})
  },
  createProfile (context, artisansId) {
    const payload = {
      banner_1: null,
      hello_txt: null,
      about_img: null,
      about_ttl: null,
      about_txt: null,
      tam_gallery: null,
      video: null,
      video_ttl: null,
      video_txt: null,
      profile_pic: null,
      position: null,
      banner_2: null,
      bio_txt: null,
      contact: null,
      social: null,
      artisans_id: null
    }

    payload.artisans_id = artisansId

    return axios.post('/specialgoods/profile', payload, {
      headers: {
        'Authorization': `Bearer ${JwtService.getToken()}`
      }
    })
  },
  resetPassword(_, email) {
    return axios.post('/wholesale/reset-password', { email }).then((res) => {
      return res
    })
  },
  register({ commit, dispatch }, credentials) {
    return axios
      .post('/frontend/register', credentials, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        if (res.data.success) {
          dispatch('loginRetail', {
            email: credentials.email,
            password: credentials.password_confirmation,
          })
        }
        return res
      })
  },
  logout({ commit }) {
    JwtService.destroyToken()
    commit('SET_AUTH', false)
    commit('SET_USER', null)

    const isCraftsmanLogged = getDataFromLocalStorage('isCraftsmanLogged')
    deleteDataFromLocalStorage('isCraftsmanLogged')
    if (isCraftsmanLogged) {
      router.push({ path: '/admin/craftsman/login' })
    } else {
      router.push({ name: 'login' })
    }
  },
  setLocaleLang({ commit }, lang) {
    window.sessionStorage.setItem('tumar-locale', lang)
    commit('SET_LOCALE_LANG', lang)
  },
  fetchLocales({ commit }) {
    const api = '/frontend/locale'
    return axios.get(api).then((res) => {
      commit('SET_LOCALES', res.data)
    })
  },
}
