<template>
  <div
    class="icon-content d-flex"
    v-if="props.href"
    :class="{
      'flex-column': props.contentAlign === 'bottom',
      'flex-row-reverse': props.contentAlign === 'left',
    }"
    :style="{ textAlign: props.textAlign, 
      alignItems: props.contentAlign === 'right' && props.title ? 'baseline' : 'normal', }"
  >
      <a
        :href="props.href"
        class="icon-content__img"
        :class="[
          props.contentAlign === 'bottom'
            ? 'align-self-' + props.imageAlign
            : '',
        ]"
        :style="{
          height: props.imageHeight,
          maxWidth: props.imageWidth,
          minWidth: props.imageWidth,
          marginRight: props.contentAlign === 'right' && props.title ? props.imagePaddingRight : '0',
          marginBottom:
            props.contentAlign === 'bottom' && props.title ? props.imagePadding || '18px' : '0',
        }"
      >
        <img v-lazy="'https://admin.tumar.com/' + props.imgSrc" :alt="getImageName(props.imgSrc)" :title="getImageName(props.imgSrc)" 
        />
      </a>
    <div class="d-flex flex-column">
      <a
        v-if="props.title"
        :href="props.href"
        class="icon-content__title"
        v-html="props.title"
        :class="{ 'no-events': !props.href }"
        :style="{paddingBottom: props.titlePadding, color: props.titleColor}"
      ></a>    
      <a
        v-if="isDesktop || !props.textHeight"
        :href="props.href"
        v-html="props.text"
        class="icon-content__text"
        :style="{color: props.textColor}"
        :class="{ 'no-events': !props.href }"
      ></a>
      <a
        v-else
        :href="props.href"
        :style="{ height: props.textHeight, color: props.textColor }"
        v-html="props.text"
        class="icon-content__text"
        :class="{ 'no-events': !props.href }"
      ></a>
      <a
        v-if="props.href"
        :href="props.href"
        :style="{color: props.linkColor}"
        class="icon-content__link"
        :class="{ 'no-events': !props.href }"
      >
        {{ props.buttonText }}
      </a>
    </div>
  </div>
  <div v-else 
      class="icon-content d-flex"
      :class="{
        'flex-column': props.contentAlign === 'bottom',
        'flex-row-reverse': props.contentAlign === 'left',
      }"
      :style="{ textAlign: props.textAlign, 
        alignItems: props.contentAlign === 'right' && props.title ? 'baseline' : 'normal', }"
  >
      <div
        class="icon-content__img"
        :class="[
          props.contentAlign === 'bottom'
            ? 'align-self-' + props.imageAlign
            : '',
        ]"
        :style="{
          height: props.imageHeight,
          maxWidth: props.imageWidth,
          minWidth: props.imageWidth,
          marginRight: props.contentAlign === 'right' && props.title ? props.imagePaddingRight : '0',
          marginBottom:
            props.contentAlign === 'bottom' && props.title ? props.imagePadding || '18px' : '0',
        }"
      >
        <img v-lazy="'https://admin.tumar.com/' + props.imgSrc":alt="getImageName(props.imgSrc)" :title="getImageName(props.imgSrc)"/>
      </div>
      <div class="d-flex flex-column">
        <p
          v-if="props.title"
          class="icon-content__title"
          v-html="props.title"
          :style="{paddingBottom: props.titlePadding, color: props.titleColor}"
        ></p>
        <p
          v-if="isDesktop || !props.textHeight"
          v-html="props.text"
          class="icon-content__text"
          :style="{color: props.textColor}"
        ></p>
        <p
          v-else
          :style="{ height: props.textHeight, color: props.textColor }"
          v-html="props.text"
          class="icon-content__text"
        ></p>
      </div>
  </div>
</template>

<script>
import { getImageName } from '@/utils/utils'

export default {
  props: {
    props: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isDesktop: window.innerWidth > 1200,
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      this.isDesktop = window.innerWidth > 1200
    },
    getImageName,
  },
}
</script>

<style scoped lang="scss">
.icon-content {
  /* text-align: center; */
  // max-width: 200px;
  // padding-top: 30px;
  &__img {
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: auto;
      display: block;
      object-fit: contain;
      text-align: center;
    }
  }
  &__title {
    font-size: 18px;
    line-height: 18px;
  }
  &__text {
    font-size: 17px;
  }
  &__link {
    font-size: 17px;
    font-weight: bold;
    color: #666666 !important;
  }
}
.no-events {
  pointer-events: none;
}
@media (max-width: 979px) {
  .icon-content__img {
    height: 100%!important;
    min-width: auto!important;
  }
}
</style>
