export default {
  SET_ARTISAN(state, artisan) {
    state.artisan = artisan
    localStorage.setItem('artisan', JSON.stringify(artisan))
  },
  REMOVE_ARTISAN(state) {
    state.artisan = {}
    localStorage.removeItem('artisan')
  }
}
