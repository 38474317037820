export default [
    {
        name: 'craftsman-profile',
        path: 'craftsman-profile',
        component: () => import('@/view/pages/Profile.vue'),
        meta: {
            title: 'Admin | Craftsman Profile',
            requiresAuth: true,
        }
    },
    {
        name: 'craftsman-reviews',
        path: 'craftsman-reviews',
        component: () => import('@/view/pages/Reviews.vue'),
        meta: {
            title: 'Admin | Craftsman Reviews',
            requiresAuth: true,
        }
    },
    {
        name: 'craftsman-company-page',
        path: 'craftsman-company-page',
        component: () => import('@/view/pages/CompanyPage.vue'),
        meta: {
            title: 'Admin | Craftsman Company page',
            requiresAuth: true,
        }
    },
    {
        name: 'craftsman-order-history',
        path: 'craftsman-order-history',
        component: () => import('@/view/pages/CraftsmanOrderHistory.vue'),
        meta: {
            title: 'Admin | Craftsman order history',
            requiresAuth: true,
        },
    },
    {
        name: 'craftsman-order-description',
        path: 'craftsman-order-history/special-product-description/:id',
        component: () => import('@/view/pages/CraftsmanOrderAccount.vue'),
        meta: {
            title: 'Admin | Special Product Description',
            requiresAuth: true,
        },
    }
]
