export default {
  SET_SPECIAL_CATEGORIES(state, specialCategories) {
    state.specialCategories = specialCategories
  },
  SET_DEFAULT_CATEGORY(state, category) {
    state.defaultCategory = category
  },
  SET_CATEGORY(state, category) {
    state.category = category
  },
  CLEAN_SELECTED_CATEGORY(state) {
    state.category = {}
    state.defaultCategory = {}
  },
}
