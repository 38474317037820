<template>
  <div class="list_items">
    <div class="p_img">
      <img
        :src="'https://admin.tumar.com' + product.photo"
        :alt="getImageName(product.photo)" :title="getImageName(product.photo)" 
      />
      <button v-if="false" class="add_to_cart" @click.stop="$emit('goToDetails', product)">
        ADD TO CART
      </button>
      <!-- <span class="sale">Sale</span> -->
    </div>
    <div class="p_text">
      <p>{{ product.name.ru }}</p>
      <div class="price">
        <div
          class="new_price notranslate"
        >
          <span>{{ product.sale_cost }} KGS</span>
          <span v-if="false">currency?.currency</span>
        </div>
        <div v-show="false" class="new_price notranslate">
          <span>{{ }}</span>
          <span>currency?.currency</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState: State_Auth } = createNamespacedHelpers('auth')
import { getImageName } from '@/utils/utils'

export default {
  props: {
    product: {
      type: Object,
      default: () => {},
    }
  },
  computed: {
    ...State_Auth(['localeLang']),
  },
  methods: {
    getImageName,
    productPrice(price, discount) {
      return Number(price - discount).toFixed(2)
    },
  },
}
</script>

<style scoped>
.new_price span {
  font-weight: 700;
}
.list_items {
  height: 100%;
}
.p_img {
  pointer-events: none;
}
p {
  color: #757474 !important;
  width: 100% !important;
  padding: 0 10px !important;
  text-align: center !important;
}
</style>
