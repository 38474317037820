<template>
  <div
    :style="{
      marginTop: props.marginTopValue + 'px',
      marginBottom: props.marginBottomValue + 'px',
      marginLeft: props.marginLeftValue + 'px',
      marginRight: props.marginRightValue + 'px',
    }"
  >
    <div
        class="imageContaner"
        :style="{ width: props.width, height: props.height }"
        @mouseover="isHover = true"
        @mouseleave="isHover = false"
    >
      <img
        v-lazy="'https://admin.tumar.com/' + props.src"
        :alt="getImageName(props.src)" :title="getImageName(props.src)" 

      />
      <transition name="fade">
        <div
            v-if="isHover"
            class="image-container__content"
        >
          <div
              v-show="props.description || props.href"
              class="d-flex flex-column align-center"
          >
            <p
                v-show="props.description"
                class="image-container__p text-center mb-3"
                v-html="props.description"
            ></p>
            <a
                v-show="props.buttonText"
                class="image-container__link px-10 py-2"
                :href="props.href"
                :style="{
          color: props.buttonTextColor,
          backgroundColor: props.buttonColor,
        }"
            >
              {{ props.buttonText }}
            </a>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { getImageName } from '@/utils/utils'

export default {
  name: 'ImageComponentWithHover',
  methods: {
    getImageName,
  },
  props: {
    props: {
      type: Object,
      default: () => {},
    },
  },
  data () {
    return {
      isHover: false
    }
  }
}
</script>

<style lang="scss" scoped>
img {
  height: 100%;
  width: 100%;
  display: block;
}
p {
  margin: 0 auto;
  width: 80%;
  color: #fff !important;
}
.image-container {
  &__p {
    display: flex;
    justify-content: center;
  }
  &__content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.7);
  }
  &__link {
    text-decoration: none;
    width: fit-content;
    background-color: rgb(197, 102, 29);
    border: 1px solid #e2e2e2;
    color: #fff !important;
    text-transform: capitalize;
  }
}
.imageContaner {
  position: relative;
  margin-bottom: 20px;
}
@media (max-width: 979px) {
  .imageContaner {
    height: auto !important;
    margin-bottom: 10px !important;
  }
}

@media (max-width: 768px) {
  .container_mobile-v-craft .imageContaner {
    width: 100%!important
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
