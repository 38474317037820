<template>
  <form @submit.prevent="sendForm" class="form">
    <h6>Send us an e-mail</h6>
    <div class="form__input-wrapper mb-4">
      <input
        class="form__input"
        type="text"
        placeholder="Your name"
        v-model="name"
      />
      <input
        class="form__input"
        type="email"
        placeholder="Your email"
        v-model="email"
      />
    </div>
    <input
      class="form__input mb-4"
      type="text"
      placeholder="Subject"
      v-model="subject"
    />
    <textarea
      class="form__input-message mb-4"
      name=""
      id=""
      cols="30"
      rows="10"
      placeholder="Message"
      v-model="message"
    ></textarea>
    <button
      class="form__button"
      :class="{ disabled: isFormInvalid }"
      type="submit"
      :style="
        !isFormInvalid && {
          color: props.buttonTextColor,
          backgroundColor: props.buttonColor,
        }
      "
    >
      SEND
    </button>
    <div v-if="showMessage" class="message-senden">Message sent successfully</div>
  </form>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapActions } = createNamespacedHelpers('faq')

export default {
  props: {
    props: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      name: '',
      email: '',
      subject: '',
      message: '',
      showMessage: false,
    }
  },
  computed: {
    isFormInvalid() {
      const { name, email, subject, message } = this
      return !name || !email || !subject || !message
    },
  },
  methods: {
    ...mapActions(['createFeedback']),
    sendForm() {
      if (this.isFormInvalid) {
        return
      }
      const { name, email, subject, message } = this
      const data = { name, email, subject, message }
      this.createFeedback(data)
      this.clearData()
      this.$emit('sendForm')

      this.showMessage = true
      setTimeout(() => {
        this.showMessage = false
      }, 3000)
    },
    clearData() {
      this.name = ''
      this.email = ''
      this.subject = ''
      this.message = ''
    }
  },
}
</script>

<style lang="scss" scoped>
.form {
  h6 {
    margin-bottom: 14px;
  }
}
.form__input-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}
.form__input {
  height: 35px;
  border: 0.7px solid #dcdcdc;
  outline: none;
  font-size: 14px;
  padding: 10px 21px;
  width: 100%;
}
.form__input-message {
  border: 0.7px solid #dcdcdc;
  outline: none;
  padding: 19px 30px;
  resize: none;
  height: 140px;
}
.form__button {
  display: block;
  width: 185px;
  margin-left: auto;
  padding: 11px 39px;
  border: none;
  background-color: #cfebcc;
  font-size: 12px;
  line-height: 117.49%;
  letter-spacing: 0.13em;
  cursor: pointer !important;
  font-weight: 700;
}
.form__button.disabled {
  background-color: #f2f2f2;
  color: grey;
  cursor: default !important;
}
.message-senden {
  margin-top: 10px;
  background: rgb(195, 247, 195);
  color: #54ae00;
  text-align: center;
  padding: 10px;
  font-weight: bold;
}
</style>