import axios from '../../../api.service'

export default {
  addMessage({ commit }, { chatId, message }) {
    commit('ADD_MESSAGE', { chatId, message })
  },

  // =======================>>>>>>>> Retail
  async postMessage({ commit }, result) {
    const api = '/frontend/chat/send_message'
    try {
      const res = await axios.post(api, result, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json;charset=utf-8',
        },
      })
      return res
    } catch {
      return 'error'
    }
  },
  async fetchMessages({ dispatch, commit }) {
    const api = '/frontend/chat'
    const response = await axios.get(api)
    commit('SCROLL_TO_NEW_MESSAGES', response?.data)
    commit('GET_MESSAGES', response?.data)
    const readed_messages = response?.data?.data
      ?.filter((el) => el.author_type === 'manager')
      .map((el) => el.id)
    dispatch('makeMessagesReaded', {
      chat_message_ids: readed_messages,
    })
  },
  async fetchChatInfo({ dispatch, commit }) {
    const api = '/frontend/chat/info'
    const response = await axios.get(api)
    commit('SET_CHAT_INFO', response?.data)
  },
  async loadMoreMessages({ dispatch, commit }, { url }) {
    if (url) {
      const data = await axios.get(url)
      commit('LOAD_MORE_MESSAGES', data.data.data)
      if (!data.data.next_page_url) {
        commit('RESET_NEXT_PAGE')
      } else {
        commit('SET_NEXT_PAGE', data.data.next_page_url)
      }
      return data.data
    }
  },
  async makeMessagesReaded({ commit }, result) {
    const api = '/frontend/chat/read_message'
    const res = await axios.put(api, result, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=utf-8',
      },
    })
    return res
  },
  async markMessageByColor({ commit }, result) {
    const api = '/frontend/chat/mark_message'
    const res = await axios.put(api, result, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=utf-8',
      },
    })
    return res
  },
  async getMarkedMessages({ commit }, color) {
    const api = `/frontend/chat?mark=${color}`
    const res = await axios.get(api)
    commit('SET_MARKED_MESSAGES', res.data)
  },
  async getMediaMessages({ commit }, type) {
    const api = '/frontend/chat?type=file'
    const res = await axios.get(api)
    commit('SET_MEDIA_MESSAGES', res.data)
  },

  // =======================>>>>>>>> Wholesale
  async postMessageWholesale({ commit }, result) {
    const api = '/wholesale/chat/send_message'
    const res = await axios.post(api, result, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=utf-8',
      },
    })

    return res
  },
  async fetchMessagesWholesale({ dispatch, commit }) {
    const api = '/wholesale/chat'
    const response = await axios.get(api)
    commit('SCROLL_TO_NEW_MESSAGES', response?.data)
    commit('GET_MESSAGES', response?.data)
    const readed_messages = response?.data?.data
      ?.filter((el) => el.author_type === 'manager')
      .map((el) => el.id)
    if (readed_messages.length) {
      dispatch('makeMessagesReaded', {
        chat_message_ids: readed_messages,
      })
    }
    return response
  },
  async fetchChatInfoWholesale({ dispatch, commit }) {
    const api = '/wholesale/chat/info'
    const response = await axios.get(api)
    commit('SET_CHAT_INFO', response?.data)
  },
  async loadMoreMessagesWholesale({ dispatch, commit }, { url }) {
    if (url) {
      const { data } = await axios.get(url)
      commit('LOAD_MORE_MESSAGES', data?.data)
      if (!data.data.next_page_url) {
        commit('RESET_NEXT_PAGE')
      } else {
        commit('SET_NEXT_PAGE', data.data.next_page_url)
      }
      return data.data
    }
  },
  async makeMessagesReadedWholesale({ commit }, result) {
    const api = '/wholesale/chat/read_message'
    const res = await axios.put(api, result, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=utf-8',
      },
    })
    return res
  },
  async markMessageByColorWholesale({ commit }, result) {
    const api = '/wholesale/chat/mark_message'
    const res = await axios.put(api, result, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=utf-8',
      },
    })
    return res
  },
  async getMarkedMessagesWholesale({ commit }, color) {
    const api = `/wholesale/chat?mark=${color}`
    const res = await axios.get(api)
    commit('SET_MARKED_MESSAGES', res.data)
  },
  async getMediaMessagesWholesale({ commit }, type) {
    const api = '/wholesale/chat?type=file'
    const res = await axios.get(api)
    commit('SET_MEDIA_MESSAGES', res.data)
  },
}
