var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"products d-block mt-2"},[(_vm.props.catigories[_vm.tab].banner)?_c('img',{staticClass:"products_banner",staticStyle:{"width":"100%","height":"auto"},attrs:{"src":_vm.props.catigories[_vm.tab].banner,"alt":_vm.getImageName(_vm.props.catigories[_vm.tab].banner),"title":_vm.getImageName(_vm.props.catigories[_vm.tab].banner)}}):_vm._e(),_c('div',{staticClass:"product_ttl_block"},[(_vm.props.title)?_c('h2',{staticClass:"product__title_"},[_vm._v(_vm._s(_vm.props.title))]):_vm._e(),(_vm.props.depiction)?_c('p',{staticClass:"product__depiction"},[_vm._v(_vm._s(_vm.props.depiction))]):_vm._e()]),_c('div',{staticClass:"title_product_",staticStyle:{"margin-left":"auto","margin-right":"auto"}},[(_vm.props.catigories.length > 1 && _vm.specialCategoriesValue.length > 0)?_c('v-tabs',{class:{ 'mb-6': _vm.$vuetify.breakpoint.smAndDown },attrs:{"hide-slider":"","show-arrows":false},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.props.catigories.map(
          (catItem) =>
            _vm.specialCategoriesValue.find(
              (specialCatItem) => specialCatItem.title.ru === catItem.catigory
            ) || {}
        )),function(item){return _c('v-tab',{key:item.id,class:{ 'px-5': _vm.$vuetify.breakpoint.mdAndUp },attrs:{"ripple":false},on:{"click":function($event){return _vm.getProductsByCategory(item)}}},[_vm._v(" "+_vm._s(item.title.ru)+" ")])}),1):_vm._e()],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('div',{staticClass:"pa-0 w-100"},[_c('transition',{attrs:{"name":"slide-fade","mode":"out-in"}},[_c('v-row',{key:"1",staticClass:"list_product width-product"},_vm._l((_vm.products),function(product){return _c('v-col',{key:product.id,staticClass:"pa-3",attrs:{"cols":"6","md":"4"}},[_c('SpecialProductCard',{attrs:{"product":product},on:{"goToDetails":() => {
                  _vm.$router.push({
                    path: `/special-product/${product.slug}`,
                    params: { id: product.slug }
                  })
                  _vm.setSelectedProduct(product)
                }},nativeOn:{"click":function($event){return (() => {
                  _vm.$router.push({
                    path: `/special-product/${product.slug}`,
                    params: { id: product.slug }
                  })
                  _vm.setSelectedProduct(product)
                }).apply(null, arguments)}}})],1)}),1)],1)],1)]),(_vm.props.catigories[_vm.tab].buttonText)?_c('div',{staticStyle:{"width":"100%","display":"flex","align-items":"center","margin-top":"20px"}},[_c('a',{staticStyle:{"margin":"0 auto"},attrs:{"href":_vm.props.catigories[_vm.tab].buttonLink}},[_c('button',{staticClass:"propduct__button"},[_vm._v(_vm._s(_vm.props.catigories[_vm.tab].buttonText))])])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }